import React, { useState, useEffect } from "react";
import {
  statuses,
  initialStateUser,
  userInfoPlaceHolders,
} from "../../Constants";
import { createUserText } from "../../language/ru";
import CreateUserRowRadio from "./CreateUserRowRadio";

const CreateUserRow = ({ create }) => {
  const [userInfo, setUserInfo] = useState(initialStateUser);
  const [status, setStatus] = useState("1");
  const [allowClick, setAllowClick] = useState(false);

  useEffect(() => {
    const { login, password, FIO } = userInfo;
    setAllowClick(login !== "" && password !== "" && FIO !== "");
  }, [userInfo]);

  const onClickCreate = () => {
    const { login, password, FIO } = userInfo;
    const allowClick = login !== "" && password !== "" && FIO !== "";

    create(login, password, status, FIO);
    setUserInfo(initialStateUser);
  };

  return (
    <div className="CreateUserRow">
      <div className="subtitle">Создание пользователя</div>
      {Object.keys(userInfo).map((key, index) => {
        return (
          <input
            className="Input"
            value={userInfo[key]}
            key={index}
            name={key}
            onChange={(e) =>
              setUserInfo({ ...userInfo, [key]: e.target.value })
            }
            placeholder={userInfoPlaceHolders[key]}
          ></input>
        );
      })}
      <CreateUserRowRadio
        elements={statuses}
        value={status}
        choose={setStatus}
      ></CreateUserRowRadio>
      <button
        onClick={allowClick ? onClickCreate : null}
        className={allowClick ? "button active" : "button"}
      >
        {createUserText}
      </button>
    </div>
  );
};
export default CreateUserRow;
