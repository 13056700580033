const initialState = [];

export default function scripts(state = initialState, action) {
  if (action.type === "LOAD_SCRIPTS") {
    return [...action.payload];
  } else if (action.type === "GET_SCRIPT") {
    var script = state[action.index];
    return script;
  }
  return state;
}
