import React from "react";
import { Link } from "react-router-dom";
import { H } from "../Constants";

const MenuBlock = ({ block: { imageUrl, title, to } }) => {
  return (
    <Link to={to} className="MenuBlock">
      <div className="img-container">
        <img src={imageUrl} className="img"></img>
      </div>
      <div className="link" >{title}</div>
    </Link>
  );
};

class MainScreen extends React.Component {
  state = {
    blocks: [
      {
        imageUrl: "./img/check.jpg",
        title: "Опросники пользователей",
        to: H.questionaries,
      },
      {
        imageUrl: "./img/categories.jpg",
        title: "Комплекты документов",
        to: H.categories,
      },
    ],
  };

  render() {
    const { blocks } = this.state;
    return (
      <div className="MainScreen">
        <div className="Tabs">
          {blocks.map((block, index) => (
            <MenuBlock block={block} key={index}></MenuBlock>
          ))}
        </div>
      </div>
    );
  }
}
export default MainScreen;
