const text = {
  title: "Создание опросника",
  prev: "Назад",
  next: "Далее",
};
export default text;

export const ignoreButton = "Все равно продолжить";
export const backButton = "Заполнить поля";
export const ignoreAlert = {
  before: "Вы не заполнили следующие поля:",
  after:
    "Отсутствие информации в опросном листе приведет к неполноте данных в документах комплекта. Просьба заполнить все поля сейчас, иначе вам необходимо будет внести эту информацию в документы самостоятельно.",
};

export const ignoreAlertPropb = {
  before: "Вы не заполнили следующие поля:",
  after:
    "Отсутствие информации в опросном листе, приведет к неполноте данных в документе. Просьба заполнить все поля сейчас, иначе вам необходимо будет внести эту информацию в документ самостоятельно.",
};
