import React, { useEffect, useState } from "react";
import SimpleReactFileUpload from "../SimpleReactFileUpload";
import { deleteText } from "../../language/ru";

import "./CategoryCardTemplatesList.scss";
import { changeTemplateName } from "../../commonFunctions/functions";

import styles from "./TemplateRow.module.scss";
import { Input } from "../../ui-kit/Input/Input";

import editIcon from "../../assets/svg/edit.svg";
import check from "../../assets/svg/check.svg";

const TemplateRow = ({
  template: { Title = "", Filename = "", Id = "" },
  fileUploadSuccess = () => {},
  onTemplateDelete = () => {},
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isEditModeWasActivated, setEditModeWasActivated] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      setEditModeWasActivated(true);
    }
  }, [isEditMode]);

  const [newTitle, setNewTitle] = useState(Title);

  const [isLoading, setIsLoading] = useState(false);

  const onEditMode = () => setEditMode(true);

  const offEditMode = () => setEditMode(false);

  const saveChanges = () => {
    setIsLoading(true);
    changeTemplateName(Id, newTitle, saveSuccess);
  };

  const saveSuccess = () => {
    setIsLoading(false);
    offEditMode();
  };

  const handleChangeTitle = (e) => setNewTitle(e.target.value);

  const handleEdit = isEditMode
    ? isLoading
      ? () => {}
      : saveChanges
    : onEditMode;

  const editButtonText = isEditMode
    ? isLoading
      ? "Загрузка.."
      : "Сохранить"
    : "Изменить";

  return (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <div onClick={handleEdit} className={styles.edit}>
          <img src={isEditMode ? check : editIcon} />
        </div>
        {isEditMode ? (
          <Input onChange={handleChangeTitle} value={newTitle}></Input>
        ) : (
          <div className={styles.title}>
            {!isEditMode && isEditModeWasActivated ? newTitle : Title}
          </div>
        )}
      </div>

      <div className={styles.filename}>
        {Filename ? Filename : "Документ отсутствует"}
      </div>
      <div className={styles.buttons}>
        <div className={styles.fileUpload}>
          <SimpleReactFileUpload
            success={fileUploadSuccess}
            Id={Id}
            Title={Filename}
          />
        </div>
        <div
          className={"dark-gray hover-red"}
          style={{ cursor: "pointer" }}
          onClick={onTemplateDelete}>
          {deleteText}
        </div>
      </div>
    </div>
  );
};
export default TemplateRow;
