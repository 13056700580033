import React, { useState } from "react";
import { T, addText } from "../../language/ru";
import { Button } from "../../ui-kit/Button/Button";
import { Input } from "../../ui-kit/Input/Input";

import styles from "./CreateCategoryBlock.module.scss"
const CreateCategoryBlock = ({ addCategory }) => {
  const [newCategoryName, setCategoryName] = useState("");

  const handleAddCategory = () => {
    addCategory(newCategoryName);
    setCategoryName("");
  };

  return (
    <div className={styles.container}>
      <Input
        placeholder={T.newComplectNamePlaceholder}
        value={newCategoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        style={{ marginRight: 20 }}
      />
      <Button style={{ marginRight: 0 }} onClick={handleAddCategory}>Добавить категорию</Button>
    </div>
  );
};
export default CreateCategoryBlock;
