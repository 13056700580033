import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ link: { href, label } }) => {
  return (
    <Link className="topline-link" to={href}>
      {label}
    </Link>
  );
};

export default CustomLink;
