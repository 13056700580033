import { combineReducers } from "redux";
import tokens from "./tokens";
import error from "./error";
import docs from "./docs";
import elements from "./elements";
import scripts from "./scripts";

export default combineReducers({
  error,
  tokens,
  docs,
  scripts,
  elements,
});
