import React, { Children } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import scriptFunctions from "../commonFunctions/scriptFunctions";
import { nameForHideStruct } from "../Constants";

export default class Questionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      elementsValues: [],
    };
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      elements: newProps.elements,
      elementsValues: newProps.elementsValues,
    });
  }

  componentDidMount() {
    const { elements, elementsValues } = this.props;
    this.setState({
      elements,
      elementsValues,
    });
  }
  render() {
    const { elements } = this.state;

    // const newElements = scriptFunctions.removeDuplicatesByProperty(
    //   elements,
    //   "Id"
    // );
    return (
      <div className="ElementContainer">
        {elements.map((element) => {
          return this.renderElement(element);
        })}
      </div>
    );
  }

  isValid = (value) => {
    return value !== null && typeof value !== "undefined" && value !== "";
  };

  checkBoxChange = (e, Title, option) => {
    const { elements, elementsValues } = this.state;
    console.log("Значения элементов", elementsValues);

    var checkedOptions = elementsValues[Title];
    // checkedOptions = "2,4"
    if (this.isValid(checkedOptions)) {
      if (this.props.isAdmin === true) {
        if (typeof checkedOptions === "string") {
          checkedOptions = checkedOptions.split(";");
        }
      }
    } else {
      checkedOptions = [];
    }

    let element = elements.find((el) => el.Title == Title);

    let { Type, children, Struct, Id } = element;

    if (Type == 7) {
      children.find((child) => child.value == option);
    }

    let toClear = null;

    if (typeof checkedOptions === "string") {
      checkedOptions = checkedOptions.split(";");
    }
    if (checkedOptions.includes(option)) {
      checkedOptions.splice(checkedOptions.indexOf(option), 1);
      toClear = option;
    } else {
      checkedOptions.push(option);
    }
    //
    // tmpOptions[Title] =
    this.props.valueChange(Title, checkedOptions, toClear /*.toString()*/);
  };

  activateInput = (Title, subTitle, index) => {
    this.myInp.focus();
  };

  delInput = (Title, index) => {
    var { elements } = this.state;
    let elementIndex = elements.findIndex((element) => element.Title == Title);
    let element = elements[elementIndex];
    let Struct = JSON.parse(element.Struct);
    Struct.struct.splice(index, 1);

    element.Struct = JSON.stringify(Struct);
    this.props.elementChange(Title, element);
  };

  addInput = (Title) => {
    var { elements } = this.state;
    let elementIndex = elements.findIndex((element) => element.Title == Title);
    let element = elements[elementIndex];
    let Struct = JSON.parse(element.Struct);
    var clearedInputsBlock = {};
    let keys = Object.keys(Struct.struct[0]);
    let flag = false;
    keys.forEach((key) => {
      if (Struct.struct[Struct.struct.length - 1][key].value == "") flag = true;
      clearedInputsBlock[key] = {
        value: "",
        displayedName: Struct.struct[0][key].displayedName,
        tip: Struct.struct[0][key].tip,
      };
    });
    if (flag) {
      alert("Сначала заполните поля последнего блока");
      return;
    }
    Struct.struct.push(clearedInputsBlock);
    element.Struct = JSON.stringify(Struct);
    this.props.elementChange(Title, element);
  };

  handleChangeType8 = (Title, index, key, value) => {
    var { elements } = this.state;
    let elementIndex = elements.findIndex((element) => element.Title == Title);
    let element = elements[elementIndex];
    let Struct = JSON.parse(element.Struct);
    // let subElIndex = Struct.struct.findIndex(subElement => subElement.Title == subTitle)
    Struct.struct[index][key].value = value;
    element.Struct = JSON.stringify(Struct);
    this.props.elementChange(Title, element);
  };

  handleChange = (e, Title) => {
    var tmpOptions = this.state.elementsValues;
    tmpOptions[Title.toString()] = e.target.value;

    this.props.valueChange(Title, e.target.value);
  };

  isActive = (isActive) => {
    return "labelRadio " + (isActive ? "activeLabel" : "");
  };

  renderElement = (element) => {
    const { elementsValues } = this.state;
    const { Id, Struct, Type, displayedName, Title, children, tip } = element;

    const trueName = displayedName !== "" ? displayedName : Title;

    const { struct = [] } = JSON.parse(Struct);
    return (
      <>
        <div key={Id} className="Element">
          <div className="d-flex flex-column w-100 ">
            {Type == 8 ? (
              <div className="ComplexList">
                <div className="d-flex flex-row m-0 bold">{trueName}</div>
                <div className="RadioElement flex-column">
                  {struct.map((inputsBlock, index) => {
                    var keys = Object.keys(inputsBlock);
                    return (
                      <div
                        key={index}
                        className="w-100 d-flex flex-column mb-3 align-items-center"
                        // style={{background: }}
                      >
                        <div className="w-100 inputsBlock">
                          {keys.map((key, i) => {
                            const { displayedName, value, tip } = inputsBlock[
                              key
                            ];

                            if (displayedName == nameForHideStruct) return;
                            return (
                              <>
                                <label
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {displayedName != "" ? displayedName : key}
                                </label>
                                <div className="tip">{tip}</div>
                                <textarea
                                  onChange={(e) => {
                                    this.handleChangeType8(
                                      Title,
                                      index,
                                      key,
                                      e.target.value
                                    );
                                  }}
                                  value={value}
                                  placeholder={""}
                                />
                              </>
                            );
                          })}
                          {index != 0 || struct.length > 1 ? (
                            <button
                              className="deleteInputsBlock Upload"
                              onClick={() => this.delInput(Title, index)}
                            >
                              Удалить элемент
                            </button>
                          ) : null}
                        </div>
                        {index == struct.length - 1 ? (
                          <button
                            className="Upload"
                            onClick={() => this.addInput(Title)}
                          >
                            Добавить элемент
                          </button>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {Type == 6 ? (
              <div className="Radio">
                <div className="d-flex flex-row m-0 bold ">
                  {displayedName !== "" ? displayedName : Title}
                </div>
                <div className="tip">{tip}</div>
                <div className="RadioElement">
                  {JSON.parse(Struct).struct.map((option, i) => {
                    return (
                      <div key={i} className="form-check">
                        <label
                          className={
                            /*(this.isActive(
                            this.props.elementsValues[Title] == input
                            )) + */ " radio1"
                          }
                          htmlFor={Title + "" + option}
                        >
                          <input
                            id={Title + "" + option}
                            type="radio"
                            style={{ display: "none" }}
                            value={option}
                            checked={this.props.elementsValues[Title] == option}
                            onChange={(e) => this.handleChange(e, Title)}
                            className="hidden"
                          />
                          <span className="label"></span>
                          {option}
                        </label>

                        {/* <label
                          className={this.isActive(
                            this.props.elementsValues[Title] == option
                          )}
                          htmlFor={Title + '' + option}
                        >
                          {option}
                        </label> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {Type == 7 ? (
              <div className="List">
                <div className="d-flex flex-row m-0 bold">
                  {displayedName !== "" ? displayedName : Title}
                </div>
                <div className="tip">{tip}</div>
                <div className="RadioElement checkbox">
                  {JSON.parse(Struct).struct.map((option, i) => {
                    return (
                      <div key={i} className="form-check">
                        <label
                          className={"d-flex align-items-center"}
                          htmlFor={Title + "" + option}
                        >
                          <input
                            id={Title + "" + option}
                            type="checkbox"
                            style={{ display: "none" }}
                            value={option}
                            checked={
                              this.props.elementsValues[Title] != null &&
                              this.props.elementsValues[Title].includes(option)
                            }
                            onChange={(e) =>
                              this.checkBoxChange(e, Title, option)
                            }
                          />
                          <span className="cbx">
                            <svg width="12px" height="12px" viewBox="0 3 12 12">
                              <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                            </svg>
                          </span>
                          <span>{option}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {Type == 4 ? (
              <div className="InputElement">
                <div className="Input d-flex flex-row m-0 bold">
                  {displayedName !== "" ? displayedName : Title}
                </div>
                <div className="tip">{tip}</div>

                <textarea
                  className="w-100 tip-input"
                  name={Id}
                  onChange={(e) => this.handleChange(e, Title)}
                  value={this.props.elementsValues[Title]}
                  // placeholder={"Введите ваш ответ"}
                />
              </div>
            ) : null}
          </div>
        </div>
        {children.map((child) => {
          const { elements, value, operatorType } = child;
          const isChildrenExist = elements.length !== 0;
          const userAnswer = this.props.elementsValues[Title];

          const isCheckbox = Type == 7;
          const isValidAnswer =
            typeof userAnswer !== "undefined" && userAnswer !== null;

          const isConditionTrue = this.conditionIsTrue(
            [userAnswer],
            value,
            operatorType
          );
          const isConditionCheckboxTrue = this.conditionCheckBoxIsTrue(
            userAnswer,
            value,
            operatorType
          );
          const isRenderUsual = isChildrenExist && isConditionTrue;
          const isRenderCheckbox =
            isCheckbox && isValidAnswer && isConditionCheckboxTrue;

          const isRenderChildren = isRenderUsual || isRenderCheckbox;

          if (isRenderChildren) {
            return this.renderElements(elements, child.Id);
          }
        })}
      </>
    );
  };
  conditionCheckBoxIsTrue = (currentValue, value, operatorType) => {
    return currentValue?.includes(value);
  };
  conditionIsTrue = (currentValue, value, operatorType) => {
    let flag = false;
    if (currentValue != null) {
      currentValue /*.split(',')*/
        .forEach((val) => {
          if (
            val == "true" ||
            (operatorType == 0 && val == value) ||
            (operatorType == 1 && parseInt(val) > parseInt(value)) ||
            (operatorType == 2 && parseInt(val) < parseInt(value))
          )
            flag = true;
        });
    }
    return flag;
  };

  renderElements = (children, elementId) => {
    return (
      <Questionary
        key={elementId}
        elements={children}
        elementChange={this.props.elementChange}
        valueChange={this.props.valueChange}
        elementsValues={this.props.elementsValues}
      />
    );
  };
}
