import React from "react";
import CategoryCardLink from "./CategoryCardLink";
import {
  complectCardsLinksWithoutScenarioId,
  complectCardsLinksWithScenarioId,
} from "../../Constants";

const CategoryCardLinks = ({ Id, scenarioId, templateTitles }) =>
  (scenarioId === null
    ? complectCardsLinksWithoutScenarioId
    : complectCardsLinksWithScenarioId
  ).map((linkInfo, index) => (
    // Если нет сценария надо вернуть только линк с false
    <CategoryCardLink
      key={index}
      linkInfo={linkInfo}
      Id={Id}
      scenarioId={scenarioId}
      templateTitles={templateTitles}
    />
  ));

export default CategoryCardLinks;
