import React, { useState } from "react";
import { complectsText, complectNameText, addComplectText } from "../../language/ru";
import CategoryItem from "./CategoryItem";

const CategoriesList = ({
  categories = [],
  createCategoryForAll = () => {},
  deleteCategoryForAll = () => {},
}) => {
  const [category, setCategory] = useState("");
  return (
    <div className="CategoriesList">
      <div className="subtitle">{complectsText}</div>

      <div className="w-100 add-block">
        <input
          className="Input"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder={complectNameText}
        ></input>
        <button
          className={category == "" ? "button" : "button active"}
          onClick={() => {
            createCategoryForAll(category);
            setCategory("");
          }}
        >
          {addComplectText}
        </button>
      </div>
      {categories.map((c) => {
        return (
          <CategoryItem
            key={c.Id}
            deleteC={() => deleteCategoryForAll(c.Id)}
            c={c}
          ></CategoryItem>
        );
      })}
    </div>
  );
};

export default CategoriesList;
