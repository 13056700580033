import React from "react";

const Radio = ({ selectedValue, name, onChange, options = [] }) => {
  return (
    <div className="d-flex" style={{ flexDirection: "column" }}>
      {options.map(({ value, label }, index) => (
        <div className="form-check">
          <label
            className={value == selectedValue ? "active radio1" : "radio1"}
            htmlFor={value}
          >
            <input
              id={value}
              name={name}
              value={value}
              style={{ display: "none" }}
              checked={value == selectedValue}
              onChange={onChange}
              type="radio"
              className="hidden"
            />
            <span className="label"></span>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};
export default Radio;
