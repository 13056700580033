import React from "react";
import TopLine from "./TopLine";

const LayoutForRouting = ({ children }) => {
  return (
    <div>
      <TopLine />
      {children}
    </div>
  );
};

export default LayoutForRouting;
