import React from "react";
import { T } from "../language/ru";

const TitleRow = ({
  title = "",
  up = () => { },
  top = false,
  bottom = false,
  down = () => { },
  struct = "",
}) => {
  struct = JSON.parse(struct)
  const docs = struct?.docs

  const formatDocsForTooltip = (docs = []) => {
    let formattedDocs = null
    console.log("struct", docs);
    if (docs.length) {
      formattedDocs = {}
      docs.forEach(doc => {
        const { value, docName, elementName } = doc
        if (!formattedDocs[value])
          formattedDocs[value] = []
        formattedDocs[value].push({ name: docName, id: elementName })
      })
    }

    return formattedDocs
  }

  const formattedDocs = formatDocsForTooltip(docs)

  return (
    <div className="top-row">
      <div className="left">{title}</div>
      <div className="right">
        <div onClick={up} style={{ display: top == true ? "none" : "block" }}>
          {T.Up}
        </div>
        <div
          onClick={down}
          style={{ display: bottom == true ? "none" : "block" }}
        >
          {T.Down}
        </div>
        {formattedDocs && <div className="documents">
          Документы
          <div className="documents__tooltip">
            {Object.keys(formattedDocs).map(answer => {
              const docs = formattedDocs[answer]
              return <div key={answer}>
                <h4>Добавляемые документы</h4>
                <h5>При ответе «{answer}»</h5>
                <div>
                  {docs.map(doc => <div key={doc.id}>
                    {doc.name} / {doc.id}
                  </div>)}
                </div>
              </div>
            })}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default TitleRow;
