import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import { H, isPropb } from "../Constants";

const styleForLabel = { marginLeft: 50, width: 200, display: "inline-block" };

const formatDate = (date = "") => {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  date = date.split(" ");
  date[0] = date[0].split("-");

  let month = date[0][1];
  month = parseInt(month);
  date[0][1] = months[month - 1];
  let buf = date[0][0];
  date[0][0] = parseInt(date[0][2]);
  date[0][2] = buf;
  date[0] = date[0].join(" ");
  return date[0] + " " + date[1];
};

const ActionsCell = ({
  isShort = false,
  ScenarioId,
  isLoadingAccounts,
  isLoadingDocuments,
  Name,
  DateTime,
  isAdmin,
  IsPayed,
  onClickCheck,
  onClickDelete,
  onClickAccount,
  onClickBuildDocuments,
  id,
}) => {
  if (isShort) {
    return (
      <div className="actions ">
        {isLoadingDocuments === false ? (
          <span
            className="href mr-10"
            onClick={() => onClickBuildDocuments(id, Name, DateTime)}
          >
            Комплект
          </span>
        ) : (
          <span className="href loading">Загрузка...</span>
        )}

        <Link
          className="href"
          to={H.oprosnikAdminShort + id + "/" + ScenarioId}
        >
          Редактирование
        </Link>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={styleForLabel} className="darkgray">
        Действия
      </div>
      <div className="actions">
        {isPropb ? null : isLoadingAccounts === false ? (
          <span
            className="href mr-10"
            onClick={() => onClickAccount(id, Name, DateTime)}
          >
            Договор и счет
          </span>
        ) : (
          <span className="href mr-10 loading">Загрузка...</span>
        )}
        {isLoadingDocuments === false ? (
          <span
            className="href mr-10"
            onClick={() => onClickBuildDocuments(id, Name, DateTime)}
          >
            Комплект документов
          </span>
        ) : (
          <span className="href mr-10 loading">Загрузка...</span>
        )}

        <Link
          className="href mr-10"
          to={H.oprosnikAdminShort + id + "/" + ScenarioId}
        >
          Редактирование
        </Link>
        {isPropb ? null : (
          <span
            className={
              IsPayed == 1
                ? "span-container payed mr-10"
                : "span-container not-payed mr-10"
            }
            onClick={() => onClickCheck(id, IsPayed)}
          >
            <span>{IsPayed == 1 ? "Оплачено" : "Не оплачено"}</span>
          </span>
        )}
        <span className="href mr-10 del" onClick={() => onClickDelete(id)}>
          Удалить
        </span>
      </div>
    </div>
  );
};

const QuestionnairsTableExpandedRow = ({
  rowData,
  onBlurComment,
  onBlurTotalSum,
}) => {
  const getPriceWithDiscount = (price, discount) => {
    price = parseInt(price);
    discount = parseInt(discount);
    price = price - price * (discount / 100);
    price = parseInt(price);
    return price;
  };

  const priceWithDiscount = getPriceWithDiscount(
    rowData.default_price,
    rowData.discount
  );

  const [total, changeTotal] = useState(rowData.Total);
  const [commentary, changeComment] = useState(rowData.Comment);

  const [wasSavedComment, saveComment] = useState(false);
  const [wasSaved, save] = useState(false);

  return (
    <div>
      <div style={styleForLabel} className="darkgray">
        Время создания
      </div>{" "}
      <span className="darkgray">{formatDate(rowData.DateTime)}</span>
      <br />
      <div style={styleForLabel} className="darkgray">
        Контактное лицо
      </div>
      {rowData.Name}
      <br />
      <div style={styleForLabel} className="darkgray">
        Название объекта
      </div>
      {rowData.Requisites}
      <br />
      <div style={styleForLabel} className="darkgray">
        Адрес объекта
      </div>
      {rowData.Address}
      <br />
      {isPropb ? null : (
        <>
          <div style={styleForLabel} className="darkgray">
            Название организации
          </div>
          {rowData.organization_name}
        </>
      )}
      <br />
      <div style={styleForLabel} className="darkgray">
        Email
      </div>
      {rowData.Email}
      <br />
      <div style={styleForLabel} className="darkgray">
        Комментарий
      </div>
      <textarea
        onChange={(e) => changeComment(e.target.value)}
        value={commentary}
        style={{
          borderBottomColor: wasSavedComment === true ? "#38c95f" : "#e5e5e5",
        }}
        onFocus={() => save(false)}
        onBlur={() => {
          onBlurComment(rowData.id, commentary);
          saveComment(true);
          setTimeout(() => {
            save(false);
          }, 2000);
        }}
        placeholder={
          isPropb
            ? "Введите комментарий к заявке"
            : "Введите комментарий к заказу"
        }
      ></textarea>
      <br />
      {isPropb ? null : (
        <>
          <div style={styleForLabel} className="darkgray">
            Итого
          </div>
          <input
            onChange={(e) => changeTotal(e.target.value)}
            value={total}
            style={{
              borderBottomColor: wasSaved === true ? "#38c95f" : "#e5e5e5",
            }}
            onFocus={() => save(false)}
            onBlur={() => {
              onBlurTotalSum(rowData.id, total);
              save(true);
              setTimeout(() => {
                save(false);
              }, 2000);
            }}
            placeholder="Введите сумму"
            type="number"
          ></input>
          <br />
          <div style={styleForLabel} className="darkgray">
            Промокод
          </div>
          {rowData.discount == 0 ||
          isNaN(rowData.discount) ||
          rowData.discount == null ||
          !rowData.discount ? (
            <>Промокод не был применен</>
          ) : priceWithDiscount != rowData.Total ? (
            <>
              Цена была изменена, скидка {rowData.discount}% от промокода{" "}
              {rowData.name} не применяется.
              <br />
              <div style={styleForLabel}>&nbsp;</div>
              Стандартная цена: {rowData.default_price}р.
              <br />
              <div style={styleForLabel}>&nbsp;</div>
              Со скидкой {rowData.discount}% по стандартной цене{" "}
              {priceWithDiscount}
              р.
            </>
          ) : (
            <>
              {rowData.name} – {rowData.discount}%.
              <br />
              <div style={styleForLabel}>&nbsp;</div>
              Цена без скидки: {rowData.default_price}р.
              <br />
              <div style={styleForLabel}>&nbsp;</div>
              Со скидкой {rowData.Total}р.
            </>
          )}
        </>
      )}
      <br />
      {isPropb ? null : (
        <>
          <div style={styleForLabel} className="darkgray">
            Статус
          </div>
          {rowData.IsPayed != 0 ? (
            <span className="green">Оплачено</span>
          ) : (
            <span className="darkgray">Не оплачено</span>
          )}
          <br />
        </>
      )}
    </div>
  );
};
const QuestionnairsTable = React.memo(
  ({
    dataList,
    qType,
    onClickCheck,
    getAccount,
    onDelete,
    onBuild,
    changeTotalSum,
    changeComment,
    isAdmin,
    isLoadingDocuments,
    isLoadingAccounts,
    IsPayed,
  }) => {
    if (qType == 0) {
      dataList = dataList.filter((q) => q.Email == "test@gmail.com");
    } else {
      dataList = dataList.filter((q) => q.Email != "test@gmail.com");
    }

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const handleExpanded = (rowData) => {
      const nextExpandedRowKeys = [rowData["id"]];
      setExpandedRowKeys(nextExpandedRowKeys);
    };

    return (
      <Table
        data={dataList}
        autoHeight
        onRowClick={(rowData) => {
          handleExpanded(rowData);
        }}
        locale={{ emptyMessage: "Загрузка.." }}
        rowKey={"id"}
        expandedRowKeys={expandedRowKeys}
        rowExpandedHeight={isPropb ? 300 : 400}
        renderRowExpanded={(rowData) => {
          return (
            <>
              <QuestionnairsTableExpandedRow
                rowData={rowData}
                onBlurTotalSum={changeTotalSum}
                onBlurComment={changeComment}
              />
              <ActionsCell
                id={rowData.id}
                IsPayed={rowData.IsPayed}
                Name={rowData.Name}
                DateTime={formatDate(rowData.DateTime)}
                onClickCheck={onClickCheck}
                onClickAccount={getAccount}
                onClickDelete={onDelete}
                onClickBuildDocuments={onBuild}
                changeTotalSum={changeTotalSum}
                isAdmin={isAdmin}
                ScenarioId={rowData.ScenarioId}
                isLoadingDocuments={isLoadingDocuments}
                isLoadingAccounts={isLoadingAccounts}
              />
            </>
          );
        }}
        cellBordered
      >
        <Column width={50} sort fixed>
          <HeaderCell></HeaderCell>
          <Cell style={{ color: "#c4c4c4" }} dataKey="id" />
        </Column>
        <Column width={250}>
          <HeaderCell>Имя заказчика</HeaderCell>
          <Cell dataKey="Name" />
        </Column>
        <Column width={350}>
          <HeaderCell>Название объекта</HeaderCell>
          <Cell dataKey="Requisites" />
        </Column>
        <Column width={140}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="Email" />
        </Column>
        <Column width={400}>
          <HeaderCell>Комментарий</HeaderCell>
          <Cell dataKey="Comment" />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Действия</HeaderCell>
          <Cell>
            {(rowData) => (
              <ActionsCell
                isShort={true}
                id={rowData.id}
                Name={rowData.Name}
                ScenarioId={rowData.ScenarioId}
                DateTime={formatDate(rowData.DateTime)}
                onClickCheck={onClickCheck}
                onClickAccount={getAccount}
                onClickDelete={onDelete}
                onClickBuildDocuments={onBuild}
                changeTotalSum={changeTotalSum}
                isAdmin={isAdmin}
                isLoadingDocuments={isLoadingDocuments}
                isLoadingAccounts={isLoadingAccounts}
              />
            )}
          </Cell>
        </Column>
      </Table>
    );
  }
);

export default QuestionnairsTable;
