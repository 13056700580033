import $ from 'jquery'
const initialState = [
  {
    id: '1',
    parent: '',
    children: [],
    docs: [
      {
        Id: '888',
        Title: '',
        Filename: ''
      }
    ]
  }
]

export function findWithAttr (array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i
    }
  }
  return -1
}

export default function docs (state = initialState, action) {
  if (action.type === 'LOAD_DOCUMENTS') {
    var docsBlocks = [...action.payload]
    for (var i = 0; i < docsBlocks.length; i++) {
      var emptyDocIndex = docsBlocks[i].docs.findIndex(doc => {
        return doc.Id === '-1'
      })
      // console.log(emptyDocIndex) === -1
      if (emptyDocIndex !== -1) {
        docsBlocks[i] = docsBlocks[i].docs.splice(emptyDocIndex, 1)
        break
      }
    }

    return [...docsBlocks]
  } else if (action.type === 'DELETE_DOC') {
    // Текущие блоки
    docsBlocks = state

    const { blockId, id } = action
    // Ищем индекс блока документов по blockId
    var blockIndex = findWithAttr(docsBlocks, 'id', blockId)
    var templateBlockIndex = findWithAttr(docsBlocks, 'id', '999')
    var docIndex = findWithAttr(docsBlocks[blockIndex].docs, 'Id', id)
    var doc = docsBlocks[blockIndex].docs[docIndex]
    // Если не нашли, то ничего не меняем
    if (blockIndex === -1) return [...state]

    docsBlocks[templateBlockIndex].docs.splice(0, 0, doc)
    // Преобразуем массив документов выбранного блока
    docsBlocks[blockIndex].docs = $.grep(docsBlocks[blockIndex].docs, function (
      e
    ) {
      return e.Id !== id
    })
    // Возвращаем преобразованный массив блоков
    return [...docsBlocks]
  } else if (action.type === 'LOAD_TEMPLATES') {
    const templates = {
      id: '999',
      parent: '',
      children: '',
      docs: [...action.payload]
    }
    return [...state, templates]
  }
  return state
}
