import React from "react";
import { useState } from "react";

export const CreateInput = ({ onSubmit, placeholder, proccessName }) => {
  const [name, setName] = useState("");
  const [discount, setDiscount] = useState("");
  return (
    <div
      className="category-card"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className="">{proccessName}</h3>
      <div className="d-flex">
        <input
          value={name}
          placeholder={placeholder}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <input
          value={discount}
          placeholder={"Скидка в процентах"}
          onChange={(e) => setDiscount(e.target.value)}
        ></input>

        <div
          className="btn"
          style={{ marginTop: 10 }}
          onClick={() => {
            if (name !== "" && name && discount !== "" && discount) {
              onSubmit(name, discount);
              setName("");
              setDiscount("");
            }
          }}
        >
          Добавить
        </div>
      </div>
    </div>
  );
};
