import React from "react";

const CreateUserRowRadio = ({ choose, value, elements }) => {
  return (
    <div className="container">
      <div className="InputRadio">
        {elements.map((el, index) => {
          const { label } = el;
          const v = el.value;
          const isChoosen = v == value;
          return (
            <div className="RadioElement" onClick={() => choose(v)}>
              <span className={isChoosen ? "label choosen" : "label"}>
                {label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreateUserRowRadio