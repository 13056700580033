import React, { useState } from "react";
import ReactPaginate from 'react-paginate';
import { connect } from "react-redux";
import Axios from "axios";
import CategoryCard from "../component/CategoryCard/CategoryCard";
import { apiUrl } from "../Constants";
import {
  getCategories,
  fetchAddCategory,
  copyCategoryFromCurrent,
} from "../api/methods";
import { addText, T } from "../language/ru";
import CreateCategoryBlock from "../component/CategoriesList/CreateCategoryBlock";
import { Input } from "../ui-kit/Input/Input";

const NewCategory = ({ onSubmit }) => {
  const [name, setName] = useState("");
  return (
    <div
      className="category-card "
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className="category-padding">{"Добавление категории"}</h3>
      <div className="category-padding">
        <input
          value={name}
          placeholder="Введите название категории"
          onChange={(e) => setName(e.target.value)}
        ></input>

        <div
          className="btn"
          style={{ marginTop: 10, width: "100%" }}
          onClick={() => {
            if (name !== "" && name) {
              setName("");
              onSubmit(name);
            }
          }}
        >
          Добавить
        </div>
      </div>
    </div>
  );
};

class Categories extends React.Component {
  state = {
    categories: [],
    newCategoryName: "",
    userType: localStorage.getItem("type"),
    findCategory: "",
    Count: 50,
    Offset: 0,
    TotalCount: 0,
    pageCount: 0,
  };

  componentDidMount() {
    this.getCategories();
  }

  successLoadCategories = (response) => {
    if (response.status == 200) {
      const totalCount = response.data.totalCount;
      const pageCount = Math.ceil(response.data.totalCount / response.data.count);
      this.setState({ categories: response.data.reverse(), totalCount, pageCount });
    }
  };
  getCategories() {
    getCategories({ body: { Count: this.state.Count, Offset: this.state.Offset }, success: this.successLoadCategories });
  }

  handlePageChange = async (e) => {
    await this.setState({ Offset: e.selected * this.state.Count });
    this.getCategories();
  };

  addCategory = async (category) => {
    fetchAddCategory({ category }, this.successAddCategory);
  };

  successAddCategory = (res) => {
    if (res.status == 201) {
      this.setState({ newCategoryName: "" });
      this.getCategories();
    }
  };

  copyCategory = (currentId, copiedId, success = () => { }) => {
    copyCategoryFromCurrent(
      { target_id: currentId, copied_id: copiedId },
      () => {
        this.getCategories();
        success();
      }
    );
  };

  deleteCategory(categoryId, Title) {
    if (
      window.confirm(
        'Вы действительно хотите удалить комплект "' + Title + '"?'
      )
    ) {
      const { Refresh_token, Access_token } =
        this.props.token || JSON.parse(localStorage.getItem("token"));
      let data = JSON.stringify({ categoryId });
      Axios({
        url: apiUrl + "?class=Category&action=deleteCategory",
        method: "DELETE",
        data,
        headers: {
          Authorization: Access_token,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.getCategories();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  deleteScenario = (id) => {
    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );
    let data = JSON.stringify({
      scenarioId: id,
    });
    Axios({
      method: "delete",
      url: apiUrl + "?class=Scenario&action=deleteScenario",
      data,
      headers: {
        authorization: Access_token,
      },
    }).then((res) => {
      this.getCategories();
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };




  render() {
    const { categories, findCategory } = this.state;



    const filtererCategories = categories && categories.filter(({ Title }) => Title.toLowerCase().includes(findCategory.toLowerCase()) || findCategory == "")

    return (
      <div className="categories">
        <CreateCategoryBlock addCategory={this.addCategory} />

        <Input
          onChange={this.handleChange}
          name="findCategory"
          value={findCategory}
          placeholder="Поиск по названию.."
        />

        {/* <ReactPaginate
          nextLabel=">"
          onPageChange={this.handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={this.state.pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        /> */}

        {filtererCategories.length == 0 ? <div className="gray mt-3">Комплектов не найдено</div> : filtererCategories.map((category, index) => {
          const { Id, Title, Translated, scenarioId } = category;


          return (
            <CategoryCard
              copyCategory={(copiedId, success) =>
                this.copyCategory(Id, copiedId, success)
              }
              key={Id}
              categories={categories}
              Id={Id}
              scenarioId={scenarioId}
              Title={Title}
              deleteScenario={() => this.deleteScenario(scenarioId)}
              deleteCategory={() => this.deleteCategory(Id, Title)}
              Translated={Translated}
            ></CategoryCard>
          );
        })}
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  token: state.token || "",
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch({ type: "CLEAR_ERROR" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
