import React, { useState, useEffect } from "react";
import Axios from "axios";
import SimpleReactFileUpload from "../SimpleReactFileUpload";
import { apiUrl } from "../../Constants";
import { addDocument, deleteDocument } from "../../api/methods";
import { documentText, addText, T } from "../../language/ru";
import CategoryCardLinks from "./CategoryCardLinks";
import CategoryCardTemplatesList from "./CategoryCardTemplatesList";
import CategoryCardAddTemplateBlock from "./CategoryCardAddTemplateBlock";

import styles from "./CategoryCard.module.scss"
import { Button } from "../../ui-kit/Button/Button";
import { Select } from "../../ui-kit/Select/Select";

const CategoryCard = ({
  Id,
  Title,
  Translated,
  copyCategory,
  categories,
  deleteScenario = () => { },
  deleteCategory = () => { },
  scenarioId = null,
}) => {
  const [templates, setTemplates] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState("");

  const [userType, setUserType] = useState(localStorage.getItem("type"));

  const [copiedCategory, setCopiedCategory] = useState(null);

  const [isCopyProcess, setIsCopyProccess] = useState(false);
  const startCopy = () => setIsCopyProccess(true);
  const finishCopy = () => setIsCopyProccess(false);

  const [isOpenedCategory, setIsOpenedCategory] = useState(false);
  const open = () => setIsOpenedCategory(true);
  const close = () => setIsOpenedCategory(false);
  const toggleOpen = () => setIsOpenedCategory(!isOpenedCategory);


  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (isOpenedCategory)
      fetchTemplates();
  }, [isOpenedCategory]);



  const fetchTemplates = () => getTemplates(Id, successLoadTemplates);

  function successLoadTemplates(res) {
    setTemplates(res)
    setIsLoaded(true)
  }

  const logTemplateById = (templates, id) => {
    const template = templates.find(template => template.Id == id)
    console.log(template);
  }

  const onDelete = (tId) => {
    if (
      window.confirm(
        'Вы действительно хотите удалить документ "' + Title + '"?'
      )
    )
      deleteDocument(tId, fetchTemplates);
  };
  const fileUploadSuccess = () => fetchTemplates()

  const addNewDocument = (newTemplateName) => {
    addDocument(Id, newTemplateName).then(() => {
      fetchTemplates()
      setNewTemplateName("");
    });
  };

  let templateTitles = {};
  templates.map((template) => {
    let { Id, Filename } = template;
    templateTitles[Id] = Filename;
  });


  const handleChangeCopiedCategory = (e) => {
    const { value } = e.target;
    setCopiedCategory(value);
  };

  const handleCopyCategory = () => {
    if (copiedCategory == null) {
      return;
    }
    startCopy();
    copyCategory(copiedCategory, finishCopySuccess);
    setCopiedCategory(null);
  };


  const finishCopySuccess = () => {
    fetchTemplates();
    finishCopy();
  };

  const handleDeleteCategory = (e) => {
    e.preventDefault()
    deleteCategory(Id, Title);
  }

  return (
    <div className={styles.category}>

      <div onClick={toggleOpen} className={styles.titleContainer}>
        <h3 style={{ marginBottom: 28 }}>{Title}</h3>

        <div className={styles.links}>
          <CategoryCardLinks
            Id={Id}
            scenarioId={scenarioId}
            templateTitle={templateTitles}
          ></CategoryCardLinks>
          {scenarioId !== null ? null : (
            <div
              className="ml-2 dark-gray hover-red"
              style={{ cursor: "pointer" }}
              onClick={handleDeleteCategory}
            >
              {T.deleteComplect}
            </div>
          )}
        </div>
      </div>
      {isOpenedCategory &&
        <div className={styles.expandedContent}>

          <div className={styles.actions}>
            {isCopyProcess ? (
              <div className={styles.copyProcess}>
                Копирование..
              </div>
            ) : (
              <CopyComplect
                handleCopyCategory={handleCopyCategory}
                handleChangeCopiedCategory={handleChangeCopiedCategory}
                categories={categories}
                copiedCategory={copiedCategory}
              />
            )}


            <h4 className={styles.documentsTitle}>Список документов</h4>


            <CategoryCardAddTemplateBlock addDocument={addNewDocument} />
          </div>
          {!isLoaded && <div className={styles.copyProcess}>
            Загрузка..
          </div>
          }
          {isLoaded ?
            templates.length == 0 ?
              <div className={styles.copyProcess}>
                Шаблоны отсутствуют
              </div> : <CategoryCardTemplatesList
                templates={templates}
                onTemplateDelete={onDelete}
                fileUploadSuccess={fileUploadSuccess}
              />
            : null}
        </div>
      }


    </div>
  );
};


const CopyComplect = ({ handleCopyCategory, handleChangeCopiedCategory, categories, copiedCategory }) => {
  return <div className={styles.copyContainer}>
    <Button onClick={handleCopyCategory}>
      Скопировать комплект
    </Button>
    <Select
      onChange={handleChangeCopiedCategory}
      placeholder="Выберите комплект для копирования.."
      value={copiedCategory}
      options={categories}
    />
  </div>
}

function getTemplates(Id, success) {
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );
  let data = JSON.stringify({
    JWT: Access_token,
    categoryId: Id,
  });
  Axios({
    method: "post",
    url: apiUrl + "?class=Template&action=getTemplates",
    data,
  })
    .then((res) => {
      const { data = [], status } = res;
      if (status !== 404 && status === 200) success(data.reverse());

    })
    .catch((err) => {
      if (success)
        success([]);
    });
}


export default CategoryCard;


