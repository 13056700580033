import React from "react";
import $, { type } from "jquery";
import Axios from "axios";
import Questionary from "../component/Questionary";
import {
  apiUrl,
  legalText,
  isValid,
  H,
  isPropb,
  listDivider,
} from "../Constants";
import { Redirect, useParams } from "react-router-dom";
import {
  getQ,
  changeAnswer,
  saveBitrixAnswer,
  completeBitrixAnswer,
} from "../api/methods";
import {
  clearArrayFromHiddenElements,
  removeFromArray,
} from "../commonFunctions/functions";
import MissedFieldsForm from "../component/MissedFieldsForm/MissedFieldsForm";
import { TitleInfo } from "../component/Oprosnik/TitleInfo";
import { ValuesGetter } from "../utils/values-getters";

class OprosnikAdmin extends React.Component {
  state = {
    scripts: [],
    choosenScript: null,
    docs: [],
    docIds: [],
    elements: [],
    elementsValues: [],
    isManyTemplates: false,
    qTitle: "",
    showOverlay: false,
    email: "",
    name: "",
    requisites: "",
    isAgree: false,
    CategoryId: null,
    ScenarioId: null,
    CategoryIdTrue: null,
    questions: {},
    isBitrixShop: false,
    saveProcessing: "NONE",
    redirectURL: "",
    isOpenMissedForm: false,
  };

  getQSuccess = (data, allElements) => {
    const { elements, questions, docs } = data;
    for (let key in elements) {
      let element = elements[key];
      if (element && typeof element !== "object" && element.includes(";")) {
        elements[key] = element.split(";");
      }
    }
    allElements = allElements.map((el) => {
      let { Title, Type, Struct, TemplateId } = el;
      console.log("title ", Title);
      if (Type == "8") {
        let valuesForList = elements[Title];

        Struct = JSON.parse(Struct);
        var clearedInputsBlock = {};
        let struct0 = Struct.struct[0];
        Struct.struct = [];
        let keys = Object.keys(struct0);

        console.log("Все значения", valuesForList);
        for (let j = 0; j < valuesForList?.length; j++) {
          const element = valuesForList[j];
          // console.log("Значения для", j, "объекта", element);

          for (let i = 0; i < element.length; i++) {
            const e = element[i];
            console.log("Значение", e, "ключа", keys[i], " для", j, "объекта");

            clearedInputsBlock[keys[i]] = {
              value: e,
              displayedName: struct0[keys[i]].displayedName,
              tip: struct0[keys[i]].tip,
            };
          }
          Struct.struct.push({ ...clearedInputsBlock });
        }
        el.Struct = JSON.stringify(Struct);
      }
      return el;
    });

    this.setState({
      elementsValues: elements,
      questions,
      docs,
      allElements,
    });
  };

  loadQuestionary = () => {
    const { uuid } = this.props.match.params;

    if (typeof uuid != "undefined") {
      this.loadScenarioFromShop(uuid);
      this.setState({ isBitrixShop: true });
      return;
    }

    const urlparams = document.location.pathname.split("/");
    const ScenarioId = urlparams.pop();
    const blankId = urlparams.pop();
    this.setState({ CategoryId: blankId, ScenarioId });
    this.getScenario(ScenarioId, blankId);
  };

  loadScenarioFromShop = (uuid) => {
    const data = { link: uuid };
    Axios({
      method: "post",
      data: JSON.stringify(data),
      url: apiUrl + "?class=Market&action=getQuestionnaire",
    })
      .then(({ data }) => {
        console.log(data);
        const questionnaireInfo = data.questionary;
        const redirectURL = data.redirect_url;
        const userAnswers = JSON.parse(data.JSON);

        const { CategoryId, Title, isDeclaration } = questionnaireInfo[0];
        const encodedElements = questionnaireInfo[0].JSON;

        this.setState({
          redirectURL,
          scripts: questionnaireInfo[0],
          qTitle: Title,
          CategoryIdTrue: CategoryId,
        });

        let allElements = this.chooseScript(0, encodedElements);
        if (userAnswers != null) {
          this.getQSuccess(userAnswers, allElements);
        }
      })
      .catch((err, q) => {
        document.location.replace(err.response.data);
        // this.setState({ error: true });
      });
  };

  getScenario = (ScenarioId, blankId) => {
    Axios({
      method: "get",
      url:
        apiUrl + "?class=Scenario&action=getScenario&scenarioId=" + ScenarioId,
    })
      .then((res) => {
        const json = res.data[0].JSON;
        const { Title, CategoryId } = res.data[0];
        this.setState({
          scripts: res.data,
          qTitle: Title,
          CategoryIdTrue: CategoryId,
        });
        let allElements = this.chooseScript(0, json);
        getQ({
          id: blankId,
          success: ({ data }) => this.getQSuccess(data, allElements),
        });
      })
      .catch((err) => {
        this.setState({ error: true });
      });
  };

  componentDidMount() {
    this.loadQuestionary();
    this.FormatStyleJquery();
    this.acceptYMAnswer();
  }
  changeFlag = (e) => {
    const buf = this.state.elements;
    buf[parseInt(e.target.name)].value = e.target.value;

    this.setState({ elements: buf });
  };
  getAllElements = (allElements, elements) => {
    elements.map((element) => {
      allElements.push(element);
      element.children.map((children) => {
        children.elements.map((elem) => {
          allElements.push(elem);
          elem.children.map((els) => {
            allElements = this.getAllElements(allElements, els.elements);
          });
        });
      });
    });
    return allElements;
  };
  chooseScript = (index, json) => {
    var elements = JSON.parse(json).filter((element) => isValid(element));
    elements = elements.filter((element) => element.Struct != "null");
    var allElements = [];
    var allElements = this.getAllElements(allElements, [...elements]).filter(
      (element) => element.Struct != "null"
    );
    var elementsValues = [];
    let isManyTemplates = false;
    let lastTemplate;
    allElements = allElements.map((el, index) => {
      let { Title, Type, Struct, TemplateId } = el;

      // Проверка, много ли шаблонов
      if (index === 0) {
        lastTemplate = TemplateId;
      }
      // Если кроме дока который мы запомнили есть еще, то документов несколько
      if (lastTemplate != TemplateId) {
        isManyTemplates = true;
      }
      if (Type == "6") elementsValues[Title] = JSON.parse(Struct).struct[0];

      return el;
    });

    this.setState({
      elements,
      allElements,
      choosenScript: index,
      elementsValues,
    });
    return allElements;
  };
  findIndexByTitle = (Title) => {
    const { allElements } = this.state;
    return allElements.findIndex((element) => element.Title == Title);
  };
  elementChange = (Title, value) => {
    var { elements } = this.state;
    let elementIndex = elements.findIndex((elem) => elem.Title == Title);
    elements[elementIndex] = value;

    // let obj = {}
    // obj[Title] = JSON.parse(elements[elementIndex].Struct).struct.map()
    this.setState({ elements });
  };

  valueChange = (Title, newValue, toChange = null) => {
    const { elements, allElements, elementsValues } = this.state;
    let bufElsVals = elementsValues;
    bufElsVals[Title] = newValue;

    var index = this.findIndexByTitle(Title);
    var element = allElements[index];

    console.log(Title, newValue, bufElsVals);
    // this.clearElementsValues(element.children, bufElsVals);

    this.setState({
      elementsValues: bufElsVals,
    });
  };
  clearElementsValues = (childrens, bufElsVals, toChange = null) => {
    childrens.map((children) => {
      children.elements.map((element) => {
        if (typeof bufElsVals[element.Title] !== "undefined") {
          if (toChange) {
            if (element.Title == toChange) {
              bufElsVals[element.Title] = null;
            }
          } else bufElsVals[element.Title] = null;
        }
        if (typeof bufElsVals[element.Title] === "undefined") {
          bufElsVals[element.Title] = null;
          this.clearElementsValues(element.children, bufElsVals);
        }
      });
    });
  };

  conditionCheckBoxIsTrue = (currentValue, value, operatorType) => {
    return currentValue.includes(value);
  };
  conditionIsTrue = (currentValue, value, operatorType) => {
    return (
      currentValue == "true" ||
      (operatorType == 0 && currentValue == value) ||
      (operatorType == 1 && parseInt(currentValue) > parseInt(value)) ||
      (operatorType == 2 && parseInt(currentValue) < parseInt(value))
    );
  };

  getDocsFromCategory = async () => {
    const { CategoryIdTrue } = this.state;

    let token = JSON.parse(localStorage.getItem("token"));

    if (typeof token !== "undefined" && token != null && token != "") {
      var { Refresh_token, Access_token } = token;
    }

    let data = JSON.stringify({
      categoryId: CategoryIdTrue,
      JWT: Access_token,
    });
    let url = apiUrl + "?class=Template&action=getTemplates";
    let resp = await Axios({
      url,
      method: "post",
      data,
    });

    let docIds = resp.data.map((template) => {
      return template.Id;
    });

    this.state.docIds = docIds;
    return docIds;
  };

  // Документы, которые нужно добавить + значения заполненных элементов + Категория в которой мы находимся
  getDocsForLoad = async (docs, elementsValues, categoryId, allElements) => {
    //

    let allDocs = docs;
    this.getDocsFromCategory(categoryId)
      .then((res) => {
        allDocs = res;

        // Документы у которых надо проверить — привязали их или нет
        let docsForConditionCheck = [];

        // Заполняем документы, у которых нужно проверить условия появляения
        allElements.forEach((element, index) => {
          const { TemplateId, Struct, Title } = element;
          let struct;
          const isStructValid =
            Struct !== null && typeof Struct !== "undefined";

          if (isStructValid) {
            struct = JSON.parse(Struct);
            let { displayConditions = [], docs = [] } = struct;

            docs.forEach((doc, index) => {
              doc.elementTitle = Title;
              docsForConditionCheck.push(doc);
            });
          }
        });

        // Документы, которые нужно будет удалить из общего списка загружаемых документов
        let docsToDeleteFromLoad = [];

        // Проходимся по всем докам, которые проверить нужно
        docsForConditionCheck.forEach((docCond, index) => {
          const { elementName, value, elementTitle } = docCond;
          const documentId = elementName;

          const answerOfUser = elementsValues[elementTitle];
          const isAnswerEqualConditional = answerOfUser == value;

          const addDocumentToRemove = () =>
            docsToDeleteFromLoad.push(documentId);

          // Если ответ пользователя не удовлетворил условиям – удаляем док
          if (!isAnswerEqualConditional) {
            addDocumentToRemove();
          }
        });

        // Удаляем из массива удаляемых те элементы, которые выполнили свое условие хоть в каком-нибудь месте
        docsForConditionCheck.forEach((docCond, index) => {
          const { elementName, value, elementTitle } = docCond;

          const documentId = elementName;

          const answerOfUser = elementsValues[elementTitle];

          let isAnswerEqualConditional = answerOfUser == value;

          const isValueHaveDivider = answerOfUser?.includes(listDivider);

          if (isValueHaveDivider) {
            isAnswerEqualConditional = answerOfUser?.includes(value);
          }

          const cancelDocumentRemove = () => {
            removeFromArray(docsToDeleteFromLoad, documentId);
          };

          if (isAnswerEqualConditional) {
            cancelDocumentRemove();
          }
        });
        docsToDeleteFromLoad = [...new Set(docsToDeleteFromLoad)];

        // Удаляем из общего массива документов те, которые нам не нужны
        docsToDeleteFromLoad.forEach((docToDelete) =>
          removeFromArray(allDocs, docToDelete)
        );

        return allDocs;
      })
      .then((res) => {
        docs = [...res];

        const { email, name, requisites, isAgree, questions, address } =
          this.state;

        const scenarioId = document.location.pathname.split("/").pop();

        let data = JSON.stringify({
          email,
          name,
          requisites,
          scenarioId,
          address,
          docs,
          questions: { ...questions },
          elements: { ...elementsValues },
        });
        if (email === "" || name === "" || requisites === "") {
          alert("Вы заполнили не все поля");
          return;
        }
        if (isAgree === false) {
          return;
        }
        if (false === true) {
          return;
        } else {
          Axios({
            method: "post",
            url: apiUrl + "?class=Scenario&action=saveAnswer",
            data,
          }).then((res) => {
            alert("Договор и счет будут направлены на ваш электронный адрес");
            document.location.replace("https://gotdoc.ru/");
            this.setState({ showOverlay: false });
          });
        }
      });
  };



  getElementsValues = (elementsValues = {}) => {
    ValuesGetter.getInputsValues(elementsValues);
    ValuesGetter.getRadioValues(elementsValues);
    ValuesGetter.getListValues(elementsValues);
    this.changeDNameOnTruth(elementsValues);

    // console.log("Значения элементов по настоящим именам", elementsValues);
  };

  changeDNameOnTruth = (elements = {}) => {
    const { allElements } = this.state;

    const fakeKeys = Object.keys(elements);

    var allEls = [];
    allEls = this.getAllElements(allEls, [...allElements]).filter(
      (element) => element.Struct != "null"
    );
    console.log("Все элементы (ищем дупликаты с разными dCond, кек", allEls);

    fakeKeys.map((oldKey) => {
      let newKey = null;

      // Находим элемент по отображаемому имени
      const element = allEls.find((element) => element.displayedName == oldKey);

      // Если нашли элемент ПО ОТОБРАЖАЕМОМУ ИМЕНИ, то newKey это Title элемента
      if (element) {
        const { Title } = element;
        if (Title !== oldKey) {
          delete Object.assign(elements, { [Title]: elements[oldKey] })[oldKey];
        }
      }
    });
  };

  // Документы, которые нужно добавить + значения заполненных элементов + Категория в которой мы находимся
  onClickChangeAnswer = async () => {
    let {
      questions = {},
      CategoryId,
      docs = [],
      docIds,
      elements,
      isBitrixShop,
      isOpenMissedForm,
    } = this.state;

    if (isBitrixShop && !isOpenMissedForm) {
      this.setState({ isOpenMissedForm: true });
      return;
    }

    let allElements = [];
    let elementsValues = {};
    allElements = this.getAllElements(allElements, elements);
    this.getElementsValues(elementsValues);


    await allElements.map((element) => {
      // await clearArrayFromHiddenElements(elementsValues, questions);

      let { Title, Type, TemplateId } = element;
      let elementNames = Object.keys(elementsValues);



      const isValidList =
        Type == 7 &&
        typeof elementsValues[Title] !== "undefined" &&
        elementsValues[Title] !== null;

      const isComplexList = Type == 8;

      // Если элемент - список и в нем есть хоть какое-то значение объединяем их через ;
      if (isValidList) {
        let elementValue = elementsValues[Title];

        const isNotEmptyList =
          elementValue.length != 0 && typeof elementValue != "string";

        if (isNotEmptyList) {
          elementsValues[Title] = elementValue.join(";");
        }
      }

      if (isComplexList) {
        let Struct = JSON.parse(element.Struct);
        const isNotValidStruct = typeof Struct.struct === "undefined";

        if (isNotValidStruct) {
          return;
        }

        const complexListValue = ValuesGetter.getComplexListValuesByTitle(element.displayedName)

        if (complexListValue) {
          elementsValues[Title] = complexListValue;
        }
      }

      // Добавляются все документы, которые присутствовали в принципе при проведении опросника

      let value = elementsValues[Title];

      const isDocumentContainTemplate = docs.includes(TemplateId);

      const isElementContainsInAnswers = elementNames.includes(Title);

      const isNeedAddTemplate =
        isElementContainsInAnswers && !isDocumentContainTemplate;

      if (isNeedAddTemplate) {
        console.log("Элемент", Title, "добавит:", TemplateId, value);
        if (isValid(value)) {
          docs.push(TemplateId);
        }
      }
    });

    let allDocs = docIds;

    this.getDocsFromCategory(CategoryId)
      .then((res) => {
        allDocs = res;

        // Документы у которых надо проверить — привязали их или нет
        let docsForConditionCheck = [];

        // Заполняем документы, у которых нужно проверить условия появляения
        allElements.forEach((element, index) => {
          const { TemplateId, Struct, Title } = element;
          let struct;
          const isStructValid =
            Struct !== null && typeof Struct !== "undefined";

          if (isStructValid) {
            struct = JSON.parse(Struct);
            let { displayConditions = [], docs = [] } = struct;

            docs.forEach((doc, index) => {
              doc.elementTitle = Title;
              docsForConditionCheck.push(doc);
            });
          }
        });

        // Документы, которые нужно будет удалить из общего списка загружаемых документов
        let docsToDeleteFromLoad = [];

        // Проходимся по всем докам, которые проверить нужно
        docsForConditionCheck.forEach((docCond, index) => {
          const { elementName, value, elementTitle } = docCond;
          const documentId = elementName;

          const answerOfUser = elementsValues[elementTitle];
          const isAnswerEqualConditional = answerOfUser == value;

          const addDocumentToRemove = () =>
            docsToDeleteFromLoad.push(documentId);

          // Если ответ пользователя не удовлетворил условиям – удаляем док
          if (!isAnswerEqualConditional) {
            addDocumentToRemove();
          }
        });

        // Удаляем из массива удаляемых те элементы, которые выполнили свое условие хоть в каком-нибудь месте
        docsForConditionCheck.forEach((docCond, index) => {
          const { elementName, value, elementTitle } = docCond;

          const documentId = elementName;

          const answerOfUser = elementsValues[elementTitle];

          let isAnswerEqualConditional = answerOfUser == value;

          const isValueHaveDivider = answerOfUser?.includes(listDivider);

          if (isValueHaveDivider) {
            isAnswerEqualConditional = answerOfUser?.includes(value);
          }

          const cancelDocumentRemove = () => {
            removeFromArray(docsToDeleteFromLoad, documentId);
          };

          if (isAnswerEqualConditional) {
            cancelDocumentRemove();
          }
        });

        docsToDeleteFromLoad = [...new Set(docsToDeleteFromLoad)];

        // Удаляем из общего массива документов те, которые нам не нужны
        docsToDeleteFromLoad.forEach((docToDelete) =>
          removeFromArray(allDocs, docToDelete)
        );

        return allDocs;
      })
      .then((res) => {
        docs = [...res];


        for (let key in elementsValues) {
          let el = elementsValues[key];
          if (el && typeof el === "object") {
            el = el.join(";");
          }
        }


        const data = {
          docs,
          questions,
          elements: { ...elementsValues },
        };

        console.log("данные для сохранения", data);
        const { uuid } = this.props.match.params;
        const { isBitrixShop } = this.state;
        this.setState({ saveProcessing: "LOADING" });

        if (isBitrixShop) {
          saveBitrixAnswer({
            json: data,
            link: uuid,
            success: this.changeBitrixAnswerSuccess,
            error: this.finishFail,
          });
          return;
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        changeAnswer({
          json: data,
          id: CategoryId,
          success: this.finish,
          error: this.finishFail,
        });
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      });
  };



  changeBitrixAnswerSuccess = () => {
    document.location.replace(this.state.redirectURL);

    this.setState({ saveProcessing: "SUCCESS" });
  };
  finishFail = () => {
    this.setState({ saveProcessing: "FAIL" });
  };

  finish = () => {
    this.setState({ saveProcessing: "SUCCESS" });

    const url = isPropb ? "/rdadmin/questionaries" : "/questionaries";
    this.redirect(url);
  };

  redirect = (url) => {
    document.location.replace(url);
  };

  FormatStyleJquery = () => {
    $(".top-line").css("display", "none");
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  acceptForm = () => {
    const { uuid } = this.props.match.params;

    completeBitrixAnswer({
      link: uuid,
      success: () => {
        this.acceptYMAnswer();
        this.onClickChangeAnswer();
      },
      error: () => {
        alert(
          "Неудачная попытка сохранения. Пожалуйста, проверьте интернет-соединение или повторите попытку позже"
        );
      },
    });
  };

  acceptYMAnswer = () => {
    setTimeout(() => {
      window.ym(52918237, "reachGoal", "accept");
    }, 10000);
  };

  closeModal = () => {
    this.setState({ isOpenMissedForm: false });
  };

  continue = () => { };
  render() {
    const {
      scripts,
      choosenScript,
      docs,
      elements,
      elementsValues,
      error,
      isManyTemplates,
      qTitle,
      showOverlay,
      email,
      name,
      requisites,
      isAgree,
      saveProcessing,
      isOpenMissedForm,
      isBitrixShop,
    } = this.state;
    return (
      <div className="TestWebSite Oprosnik container">
        {error === true ? <Redirect to={H.questionaries} /> : null}

        <TitleInfo isAdmin qTitle={qTitle} />

        <div className="content">
          <div className="d-flex flex-column w-100">
            <Questionary
              elements={elements}
              elementsValues={elementsValues}
              valueChange={this.valueChange}
              elementChange={this.elementChange}
              isAdmin={true}
            />
            {isBitrixShop ? (
              <div
                style={{
                  paddingTop: 60,
                  borderTop: "1px solid #eee",
                  marginBottom: 30,
                }}
              >
                <div
                  className="agrees"
                  style={{ margin: 0 }}
                  onClick={() => {
                    this.setState({ isAgree: !isAgree });
                  }}
                >
                  <div
                    className={
                      isAgree === true ? "checkbox checked" : "checkbox"
                    }
                    style={{ marginTop: 6 }}
                  >
                    <div className="check"></div>
                  </div>

                  <div className="d-flex flex-column ">
                    <div
                      className="legaltext"
                      style={{
                        fontSize: 16,
                        lineHeight: "180%",
                        color: "#333",
                      }}
                    >
                      Я согласен на обработку персональных данных
                    </div>
                  </div>
                </div>
                <div
                  className="legaltext "
                  style={{
                    fontSize: 16,
                    lineHeight: "180%",
                    color: "#999",
                  }}
                >
                  Нажимая кнопку «Сохранить», я подтверждаю, что ознакомился с
                  <a href="https://gotdoc.ru/politika.pdf">
                    {" "}
                    Политикой конфиденциальности персональных данных
                  </a>{" "}
                  и даю согласие на обработку всех моих персональных данных,
                  указанных в анкете, а также на получение информации.
                </div>
              </div>
            ) : null}
            <div
              className="Upload"
              style={{
                backgroundColor:
                  saveProcessing == "LOADING" ||
                    (isAgree == false && isBitrixShop)
                    ? "#999"
                    : saveProcessing == "SUCCESS"
                      ? "#3ec762"
                      : "#48b1fd",
              }}
              onClick={
                isAgree == false && isBitrixShop
                  ? null
                  : saveProcessing == "LOADING"
                    ? null
                    : this.onClickChangeAnswer
              }
            >
              {saveProcessing == "LOADING"
                ? "Загрузка"
                : saveProcessing == "SUCCESS"
                  ? "Успешно сохранено"
                  : "Сохранить"}
            </div>
            {saveProcessing == "FAIL" ? (
              <p style={{ marginTop: 20 }} className="text-center">
                Не удалось сохранить данные. <br /> Проверьте интернет
                соединение или повторите попытку позже.
              </p>
            ) : null}
          </div>
        </div>

        {isOpenMissedForm && (
          <MissedFieldsForm
            closeModal={this.closeModal}
            onAccept={this.acceptForm}
            onSave={this.onClickChangeAnswer}
            isOpenForm={isOpenMissedForm}
          />
        )}
      </div>
    );
  }
}

export default OprosnikAdmin;
