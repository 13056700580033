import React, { useState } from "react";
import { maxLengthTemplateName } from "../../Constants";
import { T, addText } from "../../language/ru";
import { Button } from "../../ui-kit/Button/Button";

import styles from "./CategoryCardAddTemplateBlock.module.scss"

const CategoryCardAddTemplateBlock = ({ addDocument = () => { } }) => {
  const [newTemplateName, setNewTemplateName] = useState("");

  const onClickAdd = () => {
    addDocument(newTemplateName);
    setNewTemplateName("");
  }

  return (
    <div className={styles.container}>
      <Button onClick={onClickAdd}>Добавить шаблон</Button>
      <input
        className={styles.input}
        value={newTemplateName}
        placeholder={T.templateNamePlaceholder}
        onChange={(e) => setNewTemplateName(e.target.value)}
        style={{ paddingRight: 90 }}
        maxLength={maxLengthTemplateName}
      />
      <h5 className="text-right">{`${newTemplateName.length}/${maxLengthTemplateName}`}</h5>
    </div>
  );
};

export default CategoryCardAddTemplateBlock;
