import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import $ from "jquery";
import {
  getAnswers,
  setPayStatus,
  changeTotal,
  changeComment,
  buildDocuments,
  generateAccount,
  deleteAnswer,
} from "../api/methods";
import { H } from "../Constants";
import { downloadFile } from "../commonFunctions/functions";
import Radio from "../component/UI-kit/Radio";
import { List } from "react-virtualized";
import QuestionnairsTable from "../component/QuestionnairsTable";
import ReactPaginate from 'react-paginate';

const FormatDate = (date = "") => {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  date = date.split(" ");
  date[0] = date[0].split("-");

  let month = date[0][1];
  month = parseInt(month);
  date[0][1] = months[month - 1];
  let buf = date[0][0];
  date[0][0] = parseInt(date[0][2]);
  date[0][2] = buf;
  date[0] = date[0].join(" ");
  return date;
};

const Block = ({
  oprosnik: {
    id = "2515166",
    DateTime = "2020-05-13 15:35:38",
    Email = "konstantin.konstantinopolskiy@gmail.com",
    IsPayed,
    ScenarioId,
    Name = "Fusce quis interdum urna. Etiam nec lectus",
    Requisites = "Sed eleifend cursus dictum. Fusce quis interdum urna. Etiam nec lectus nec ante tristique laoreet vitae id massa. Fusce turpis nisi, pharetra et fermentum",
    Total = "15500",
    Comment = "Curabitur vestibulum enim vitae odio efficitur dapibus vestibulum enim vitae odio efficitur dapibus vestibulum enim vitae odio efficitur dapibus. Integer cursus malesuada rutrum. Duis eget dolor nec lacus blandit pharetra in at nunc. Nunc semper pretium risus non bibendum",
    Address = "Curabitur vestibulum enim vitae odio efficitur dapibus vestibulum",
  },
  isAdmin = false,
  isLoadingDocuments = false,
  isLoadingAccounts = false,
  onClickCheck = () => console.log("Не меняется статус оплаты"),
  onClickAccount = () => console.log("Не генерируется счет и договор"),
  onClickDelete = () => console.log("Нет события удаления"),
  onClickBuildDocuments = () => console.log("Нет события сборки документов"),

  onBlurTotalSum = () => console.log("Нет события сохранения новой суммы"),
  onBlurComment = () => console.log("Нет события изменения комментария"),
}) => {
  DateTime = FormatDate(DateTime);

  const [total, changeTotal] = useState(Total);
  const [comment, changeComment] = useState(Comment);
  const [wasSaved, save] = useState(false);
  const [wasSavedComment, saveComment] = useState(false);

  return (
    <div className="row Block">
      <div className={isAdmin ? "grid-container" : "grid-container editor"}>
        <div className="ID">
          <span className="sign right">Номер</span>
          {id}
        </div>
        <div className="Name">
          <span className="sign right">Имя</span>
          {Name}
        </div>
        <div className="DateTime">
          <span className="sign right">Время отправки</span>
          {DateTime[0]}
          <br />
          {DateTime[1]}
        </div>
        {isAdmin ? (
          <div className="Edit">
            <span className="sign right">Анкета</span>
            <Link
              className="href"
              to={H.oprosnikAdminShort + id + "/" + ScenarioId}
            >
              Редактирование
            </Link>
            <br></br>
            <span className="href del" onClick={() => onClickDelete(id)}>
              Удалить
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="Buttons">
          <span className="sign right">Документы</span>

          {isLoadingAccounts === false ? (
            <span
              className="href"
              onClick={() => onClickAccount(id, Name, DateTime)}
            >
              Договор и счет
            </span>
          ) : (
            <span className="href loading">Загрузка...</span>
          )}

          <br></br>
          {isLoadingDocuments === false ? (
            <span
              className="href"
              onClick={() => onClickBuildDocuments(id, Name, DateTime)}
            >
              Комплект документов
            </span>
          ) : (
            <span className="href loading">Загрузка...</span>
          )}
        </div>
        <div className="Address">
          <span className="sign right">Адрес</span>
          {Address}
        </div>
        <div className="Email">
          <span className="sign right">Email</span>

          <a className="Email" href={"mailto:" + Email}>
            {Email}
          </a>
        </div>
        {!isAdmin ? (
          <> </>
        ) : (
          <>
            <div className="Total">
              <span className="sign right">Сумма</span>

              <input
                onChange={(e) => changeTotal(e.target.value)}
                value={total}
                style={{
                  borderBottomColor: wasSaved === true ? "#38c95f" : "#e5e5e5",
                }}
                onFocus={() => save(false)}
                onBlur={() => {
                  onBlurTotalSum(id, total);
                  save(true);
                  setTimeout(() => {
                    save(false);
                  }, 2000);
                }}
                placeholder="Введите сумму"
                type="number"
              ></input>
            </div>
            <div className="Check">
              <span className="sign right">Статус</span>
              <div
                className={
                  IsPayed == 1
                    ? "span-container payed"
                    : "span-container not-payed"
                }
                onClick={() => onClickCheck(id, IsPayed)}
              >
                <span>{IsPayed == 1 ? "Оплачено" : "Не оплачено"}</span>
              </div>
            </div>
          </>
        )}
        <div className="Requesities cope_text line-clamp">
          <span className="sign right">Название объекта</span>
          <p>{Requisites}</p>
        </div>
        <div className="Comment cope_text line-clamp">
          <span className="sign right">Комментарий</span>

          <textarea
            onChange={(e) => changeComment(e.target.value)}
            value={comment}
            style={{
              borderBottomColor:
                wasSavedComment === true ? "#38c95f" : "#e5e5e5",
            }}
            onFocus={() => save(false)}
            onBlur={() => {
              onBlurComment(id, comment);
              saveComment(true);
              setTimeout(() => {
                save(false);
              }, 2000);
            }}
            placeholder="Введите комментарий к заказу"
            type="number"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

const Input = ({ type, label, name, value, onChange }) => {
  return (
    <div className="input-block">
      <label>{label}</label>
      <input type={type} name={name} value={value} onChange={onChange}></input>
    </div>
  );
};

const RadioPayments = ({ value, onChange }) => {
  let checked = { all: false, not: false, yes: false };
  if (typeof value == "undefined" || value == null || value == -1) {
    checked.all = true;
  }
  if (value == 0) {
    checked.not = true;
  }
  if (value == 1) {
    checked.yes = true;
  }

  const { all, not, yes } = checked;
  return (
    <div className="radio-block">
      <input type="radio" value={-1} checked={all} onChange={onChange}></input>
      <label>{"Все"}</label>
      <input type="radio" value={0} checked={not} onChange={onChange}></input>
      <label>{"Неоплачены"}</label>
      <input type="radio" value={1} checked={yes} onChange={onChange}></input>
      <label>{"Оплачены"}</label>
    </div>
  );
};

const Nothing = () => {
  return <div className="Title">Загрузка..</div>;
};

const Blocks = React.memo(
  ({
    oprosniki,
    changeIsPayed,
    getAccount,
    onDelete,
    onBuild,
    changeTotalSum,
    changeComment,
    isAdmin,
    isLoadingDocuments,
    isLoadingAccounts,
    qType,
  }) => {
    return (
      <div>
        {oprosniki.map((oprosnik) => {
          if (qType == 0) {
            if (oprosnik.Email != "test@gmail.com") {
              return;
            }
          }
          if (qType == 1) {
            if (oprosnik.Email == "test@gmail.com") {
              return;
            }
          }

          return (
            <Block
              onClickCheck={changeIsPayed}
              onClickAccount={getAccount}
              onClickDelete={onDelete}
              onClickBuildDocuments={onBuild}
              onBlurTotalSum={changeTotalSum}
              onBlurComment={changeComment}
              oprosnik={oprosnik}
              isAdmin={isAdmin}
              isLoadingDocuments={isLoadingDocuments}
              isLoadingAccounts={isLoadingAccounts}
              key={oprosnik.id}
            ></Block>
          );
        })}
      </div>
    );
  }
);

class QsForCheck extends React.Component {
  state = {
    oprosniki: [],
    Email: null,
    Name: null,
    TimeStart: null,
    TimeEnd: null,
    IsPayed: null,
    Count: 50,
    Offset: 0,
    TotalCount: 0,
    scenarioId: 0,
    isNewFilter: false,
    isLoadingDocuments: false,
    isLoadingAccounts: false,
    isAdmin: false,
    qType: 0,
    testQCount: 0,
    pageCount: 0,
  };

  checkIsAdmin = () => {
    const type = localStorage.getItem("type");
    if (type == 1) {
      this.setState({ isAdmin: true });
    } else {
      this.setState({ isAdmin: false });
    }
  };

  componentDidMount() {
    this.loadAnswers();
    this.checkIsAdmin();
  }
  loadAnswers = async () => {
    let { Email, Name, TimeStart, TimeEnd, IsPayed } = this.state;

    if (TimeEnd !== null) {
      TimeEnd += " 23:59:59";
    }

    getAnswers({
      body: { Email, Name, TimeEnd, TimeStart, IsPayed, Count: this.state.Count, Offset: this.state.Offset },
      success: this.loadSuccess,
    });
  };

  loadSuccess = async ({ data = [] }) => {
    console.log(data);
    const testQCount = data?.filter((o) => o.Email == "test@gmail.com").length;
    const totalCount = data.totalCount;
    const pageCount = Math.ceil(data.totalCount / data.count);

    this.setState({
      oprosniki: data.reverse(),
      isNewFilter: false,
      testQCount,
      totalCount,
      pageCount,
    });
  };

  handlePageChange = async (e) => {
    await this.setState({ Offset: e.selected * this.state.Count });
    this.loadAnswers();
  };

  changeIsPayed = (id, payStatus) => {
    if (payStatus == 0) {
      payStatus = 1;
    } else {
      payStatus = 0;
    }
    setPayStatus({
      id,
      payStatus,
      success: () => {
        // alert(
        //   "Оплата успешно проставлена"
        // )
        this.loadAnswers();
      },
    });
  };
  changeTotalSumSuccess = () => {
    alert("Итоговая сумма успешно обновлена");
    this.loadAnswers();
  };
  changeTotalSum = (id, total) => {
    changeTotal({ total, id, success: this.changeTotalSumSuccess });
  };
  changeComment = (id, comment) => {
    changeComment({ comment, id, success: this.loadAnswers });
  };

  getAccount = (id, name, date) => {
    generateAccount({
      id,
      success: (res) => {
        downloadFile(res, name + " " + date + " Договор_и_счет");
        this.setState({ isLoadingAccounts: false });
      },
      onStart: () => this.setState({ isLoadingAccounts: true }),
    });
  };
  build = (id, name, date) => {
    buildDocuments({
      id,
      success: (res) => {
        downloadFile(res, name + " " + date + " Пакет_документов");
        this.setState({ isLoadingDocuments: false });
      },
      onStart: () => this.setState({ isLoadingDocuments: true }),
    });
  };
  delete = (id) => {
    var x = window.confirm(
      "Вы действительно хотите удалить анкету и информацию по ней?"
    );
    if (x) {
      deleteAnswer({ id, success: this.loadAnswers });
    }
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    if (name == "TimeEnd") {
    }
    this.setState({ [name]: value, isNewFilter: true });
  };
  changeRadio = (e) => {
    const value = e.target.value;
    let IsPayed = value;
    if (IsPayed == -1) {
      IsPayed = null;
    }
    this.setState({ IsPayed, isNewFilter: true });
  };

  onPressEnter = () => {
    this.loadAnswers();
  };
  onKeyPress = (e) => {
    if (e.key === "13") {
      this.onPressEnter();
    }
  };

  handleChangeByName = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getQTypes = () => {
    const { testQCount, oprosniki } = this.state;
    return [
      {
        value: 0,
        label: `Тестовые (${testQCount})`,
      },
      {
        value: 1,
        label: `От клиентов (${oprosniki.length - testQCount})`,
      },
    ];
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
    const {
      oprosniki,
      isAdmin,
      isLoadingAccounts,
      isLoadingDocuments,
    } = this.state;

    return (
      <div key={key}>
        <Block
          onClickCheck={this.changeIsPayed}
          onClickAccount={this.getAccount}
          onClickDelete={this.delete}
          onClickBuildDocuments={this.build}
          onBlurTotalSum={this.changeTotalSum}
          onBlurComment={this.changeComment}
          oprosnik={oprosniki[index]}
          isAdmin={isAdmin}
          isLoadingDocuments={isLoadingDocuments}
          isLoadingAccounts={isLoadingAccounts}
        ></Block>
      </div>
    );
  };

  render() {
    const {
      oprosniki,
      Email,
      Name,
      TimeEnd,
      TimeStart,
      IsPayed,
      isNewFilter,
      isLoadingAccounts,
      isLoadingDocuments,
      isAdmin,
      qType,
    } = this.state;
    return (
      <div className="QsForCheck">
        <div className="container">
          <Radio
            selectedValue={qType}
            name={"qType"}
            onChange={this.handleChangeByName}
            options={this.getQTypes()}
          ></Radio>
          {/* <ReactPaginate
            nextLabel=">"
            onPageChange={this.handlePageChange}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={this.state.pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          /> */}
          <QuestionnairsTable
            dataList={oprosniki}
            qType={qType}
            onClickCheck={this.changeIsPayed}
            getAccount={this.getAccount}
            onDelete={this.delete}
            onBuild={this.build}
            changeTotalSum={this.changeTotalSum}
            changeComment={this.changeComment}
            isAdmin={isAdmin}
            isLoadingDocuments={isLoadingDocuments}
            isLoadingAccounts={isLoadingAccounts}
          />
          {/* <Blocks
            qType={qType}
            oprosniki={oprosniki}
            changeIsPayed={this.changeIsPayed}
            getAccount={this.getAccount}
            onDelete={this.delete}
            onBuild={this.build}
            changeTotalSum={this.changeTotalSum}
            changeComment={this.changeComment}
            isAdmin={isAdmin}
            isLoadingDocuments={isLoadingDocuments}
            isLoadingAccounts={isLoadingAccounts}
          /> */}
        </div>
      </div>
    );
  }
}
export default QsForCheck;
