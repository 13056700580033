import React, { useState } from "react";
import { connect } from "react-redux";
import { linksAdmin, linksEditor, H } from "../../Constants";
import CustomLink from "./CustomLink";

const TopLine = ({ signOut }) => {
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("type") == 1 ? true : false
  );

  return (
    <div
      className="top-line"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{ display: "flex" }}>
        {(isAdmin ? linksAdmin : linksEditor).map((link, index) => {
          return <CustomLink link={link} key={index}></CustomLink>;
        })}
      </div>
      <a
        onClick={(e) => {
          e.preventDefault();
          signOut("");
          document.location.replace(H.login);
        }}
        className="topline-link href exit"
      >
        Выйти
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { signOut: (payload) => dispatch({ type: "LOAD_TOKENS", payload }) };
};

export default connect(null, mapDispatchToProps)(TopLine);
