import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getUsers,
  addNewUser,
  deleteUser,
  getCategories,
  addCategory,
  deleteCategory,
  addCategoryForAll,
  deleteCategoryForAll,
} from "../api/methods";
import UserRow from "../component/UserRow";
import CreateUserRow from "../component/CreateUserRow/CreateUserRow";
import CategoriesList from "../component/CategoriesList/CategoriesList";

class UsersScreen extends React.Component {
  state = {
    users: [],
    categories: [],
  };
  componentDidMount() {
    this.loadUsers();
    this.loadCategories();
  }

  loadCategories = () => {
    getCategories({
      success: ({ data }) => this.setState({ categories: data }),
    });
  };

  loadUsers = () => {
    getUsers({
      success: ({ data = [] }) => {
        this.setState({ users: data.reverse() });
      },
    });
  };

  loadAll = () => {
    this.loadCategories();
    this.loadUsers();
  };

  createUser = (login, password, status, FIO) => {
    addNewUser({
      login,
      password,
      status,
      fio: FIO,
      success: () => {
        this.loadUsers();
      },
    });
  };
  deleteUserRow = (id) => {
    var answer = window.confirm(
      "Вы действительно хотите удалить пользователя?"
    );
    if (answer) {
      deleteUser({
        id,
        success: () => {
          this.loadUsers();
        },
      });
      //some code
    } else {
      //some code
    }
  };

  onClickAddCategory = (userId, categoryId) => {
    console.log(userId, categoryId);

    addCategory({ userId, categoryId, success: () => this.loadUsers() });
  };
  onClickDeleteCategory = (userId, categoryName) => {
    const { categories } = this.state;

    let category = categories.find((c) => c.Title == categoryName);
    let categoryId = category.Id;

    var answer = window.confirm(
      "Вы действительно хотите удалить комплект?",
      category.Title,
      "?"
    );
    if (answer) {
      deleteCategory({ userId, categoryId, success: () => this.loadUsers() });

      //some code
    } else {
      //some code
    }
  };

  deleteForAll = (Id) => {
    console.log(Id);
    let isDelete = window.confirm("Вы действительно хотите удалить комплект?");
    if (isDelete) {
      deleteCategoryForAll({ categoryId: Id, success: this.loadAll });
    }
  };
  createForAll = (category) => {
    addCategoryForAll({ category, success: this.loadCategories });
  };

  render() {
    const { users, categories } = this.state;
    return (
      <div className="UsersScreen container">
        <div className="row top-block">
          <CreateUserRow create={this.createUser}></CreateUserRow>
          <CategoriesList
            createCategoryForAll={this.createForAll}
            deleteCategoryForAll={this.deleteForAll}
            categories={categories}
          ></CategoriesList>
          {/* <CreateUserRow create={this.createUser}></CreateUserRow> */}
        </div>
        {users.map((user, index) => {
          const { Email, Password, Status, FIO } = user;
          return (
            <UserRow
              FIO={FIO}
              email={Email}
              password={Password}
              status={Status}
              key={index}
              onClickAddCategory={(categoryId) => {
                this.onClickAddCategory(user.Id, categoryId);
              }}
              onClickDeleteCategory={(categoryName) =>
                this.onClickDeleteCategory(user.Id, categoryName)
              }
              userCategories={user.categories}
              categories={categories}
              deleteUser={() => this.deleteUserRow(user.Id)}
            ></UserRow>
          );
        })}
      </div>
    );
  }
}
export default UsersScreen;
