import $ from "jquery";
import { apiUrl, isPropb } from "../Constants";
import Axios from "axios";

export const getAllVisibleTitles = () => {
  var titles = [];
  $(".d-flex.flex-row.m-0.bold").map(function () {
    let element = $(this);
    let title = element[0].innerHTML;
    titles.push(title);
  });
  return titles;
};

export function getKeyByValue(object, value) {
  for (var prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
}

// Очищаем массив значений от всего скрытого по заголовкам
export const clearArrayFromHiddenElements = async (
  elementsValues,
  questions
) => {
  // Все заголовки ключей из массива elementsValues
  let keys = Object.keys({ ...elementsValues });
  let qKeys = Object.keys(questions);

  let truthNames = [...qKeys];

  // Массив отображаемых названий всех ключей
  let allKeys = qKeys.map((key) => {
    let value = questions[key];
    if (value == "") {
      return key;
    } else {
      return value;
    }
  });
  console.log(truthNames, allKeys);

  // Все отображаемые ключи, у которых оставим значения
  let displayedTitles = getAllVisibleTitles();

  let notDisplayed = allKeys.filter((k) => !displayedTitles.includes(k));
  console.log(displayedTitles, notDisplayed);

  notDisplayed.map((k) => {
    let keyToNull = getKeyByValue(questions, k);
    elementsValues[keyToNull] = null;
  });
  console.log(elementsValues);
};

export function checkLoginPropPB() {
  if (isPropb) {
    Axios({
      method: "get",
      url: "https://propb.ru/rdapi/auth_check.php",
    }).then(({ data }) => {
      console.log(data);
      if (data.auth) {
        console.log("Auth must be true without redirect", data.auth);
        return;
      } else {
        console.log("Auth must be false. Redirect", data.auth);
        // TODO:
        // document.location.replace("https://propb.ru/personal/");
      }
    });
  }
}
export const deleteScenario = (id, success) => {
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );
  let data = JSON.stringify({
    scenarioId: id,
  });
  Axios({
    method: "delete",
    url: apiUrl + "?class=Scenario&action=deleteScenario",
    data,
    headers: {
      authorization: Access_token,
    },
  }).then((res) => {
    success();

    // document.location.reload()
  });
};

export const getScenarios = (success) => {
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );
  Axios({
    method: "get",
    url: apiUrl + "?class=Scenario&action=getScenarios",
    headers: {
      authorization: Access_token,
    },
  }).then((res) => success(res.data));
};

export function downloadFile(res, str) {
  console.log(res.data);
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", str + ".zip");
  document.body.appendChild(link);
  link.click();
}

export const getPriceWithDiscount = (price, discount) => {
  price = parseInt(price);
  discount = parseInt(discount);
  price = price - price * (discount / 100);
  price = parseInt(price);
  return price;
};

export const removeFromArray = (array, value) => {
  let index = array.indexOf(value);
  while (index > -1) {
    array.splice(index, 1);
    index = array.indexOf(value);
  }
};

export const changeTemplateName = (templateId, name, success) => {
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );

  let data = JSON.stringify({
    name,
    templateId,
  });

  Axios({
    method: "POST",
    url: apiUrl + "?class=Template&action=changeTemplateName",
    data,
    headers: {
      authorization: Access_token,
    },
  }).then((res) => {
    success();

    // document.location.reload()
  });
};
