import $ from "jquery";

const getComplexListValuesByTitle = (elementTitle) => {
    const values = []

    $(".ComplexList").map(function () {

        const { key, children } = getKeyAndChildren.call(this)
        const valuesContainer = children[1];

        if (key != elementTitle) {
            return
        }

        const valuesBlocks = $(valuesContainer).children()

        valuesBlocks.map(function () {
            const valueBlock = $(this)
            const textAreas = valueBlock.find("textarea")
            const blockValue = []
            textAreas.map(function () {
                const value = $(this).val()
                console.log(value);
                blockValue.push(value)
            })
            values.push(blockValue)
        })

    });

    return values
};

const getInputsValues = (elements = {}) => {
    $(".InputElement").map(function () {
        const { key, children } = getKeyAndChildren.call(this)
        const textarea = children[2];
        const value = $(textarea).val();
        elements[key] = value;
    });
};

const getRadioValues = (elements = {}) => {
    $(".Radio").map(function () {
        const { key, children } = getKeyAndChildren.call(this)

        const radios = children[2];
        const selectedInput = $(radios).find("input:checked");
        const value = selectedInput.val();

        elements[key] = value;
    });
};

const getListValues = (elements = {}) => {
    $(".List").map(function () {
        const { key, children } = getKeyAndChildren.call(this)
        const radios = children[2];
        const selectedInput = $(radios).find("input:checked");

        let values = [];

        selectedInput.map(function () {
            let input = $(this);
            values.push(input.val());
        });

        elements[key] = values;
    });
};

function getKeyAndChildren() {
    const element = $(this);
    const children = element.children();

    const titleEl = children[0];
    const key = titleEl.innerHTML;
    return { key, children }
}


export const ValuesGetter = {
    getComplexListValuesByTitle,
    getInputsValues, getRadioValues, getListValues
}
