const findElementWithDocsDocs = (element, docsAndChildren) => {
  if (!element) return;

  const { Struct, children, Id } = element;
  const docIds = getDocumentIdsFromStruct(Struct);

  const isDocIdsEmpty = docIds.length == 0;

  if (!isDocIdsEmpty) {
    pushDocsAndChildrenPair(docsAndChildren, docIds, children, Id);
    return;
  }

  const elements = children?.map((child) => child.elements);

  if (!elements) return;

  elements.forEach((element) =>
    findElementWithDocsDocs(element, docsAndChildren)
  );
};

const getDocumentIdsFromStruct = (structInJSON) => {
  if (structInJSON == "null" || !structInJSON) return [];
  const parsedStruct = JSON.parse(structInJSON);

  const { docs } = parsedStruct;
  return docs.map((doc) => doc.elementName);
};

const pushDocsAndChildrenPair = (
  docsAndChildren = [],
  docIds,
  children,
  elementId
) => {
  let isChildrenEmpty = children.length == 1;
  if (isChildrenEmpty) {
    return;
  }

  docsAndChildren.push({
    docIds,
    children,
    elementId,
  });
};
const pushChildrenByDocsToElements = (element, docsAndChildren) => {
  if (!element) return;

  const { Struct, children, Id } = element;
  const docIds = getDocumentIdsFromStruct(Struct);

  const isDocIdsEmpty = docIds.length == 0;

  if (!isDocIdsEmpty) {
    const foundedPair = docsAndChildren.find(
      (pair) => JSON.stringify(pair.docIds) == JSON.stringify(docIds)
    );

    if (foundedPair) {
      const { elementId } = foundedPair;
      if (elementId == Id) {
        return;
      }

      element.children = foundedPair.children;
      return;
    }
  }

  const elements = children?.map((child) => child.elements);

  if (!elements) return;

  elements.forEach((element) =>
    findElementWithDocsDocs(element, docsAndChildren)
  );
};

function removeDuplicatesByProperty(arr, property) {
  return arr.filter(
    (v, i, a) => a.findIndex((t) => t.property === v.property) === i
  );
}
export default {
  findElementWithDocsDocs,
  pushChildrenByDocsToElements,
  removeDuplicatesByProperty,
};
