import React, { useEffect, useState } from "react";

import MissedFields from "../MissedFields";

import $ from "jquery";

import text, {
  ignoreButton,
  ignoreAlert,
  backButton,
  ignoreAlertPropb,
} from "../../language/script";

import { isValid } from "../../Constants";

import styles from "./MissedFieldsForm.module.css";

const MissedFieldsForm = ({ isOpenForm, onAccept, onSave, closeModal }) => {
  const [missed, setMissed] = useState([]);
  const [isShowWarning, setIsShowWarning] = useState(false);

  useEffect(() => {
    findMissedElements();
  }, [isOpenForm]);

  const findMissedElements = () => {
    let inputs = InputElements();
    let lists = Lists();
    let complexLists = ComplexList();

    let missed = inputs.concat(lists, complexLists);

    setMissed(missed);
  };

  const onBackClick = () => {
    closeModal();
    scrollToFirstInList();
  };

  const InputElements = () => {
    let titles = [];

    $(".InputElement").map(function () {
      let element = $(this);
      let children = element.children();

      let titleBlock = children[0];
      let title = titleBlock.innerHTML;

      let textarea = children[2];
      let value = $(textarea).val();

      if (value == "") titles.push(title);
    });

    return titles;
  };

  const Lists = () => {
    let titles = [];

    $(".List").map(function () {
      let element = $(this);
      let children = element.children();
      let titleBlock = children[0];
      let title = titleBlock.innerHTML;

      let checkboxes = children[2];

      let inputs = $(checkboxes).find("input[type='checkbox']:checked");

      if (inputs.length == 0) titles.push(title);
    });

    return titles;
  };

  const ComplexList = () => {
    let titles = [];

    const haveEmpty = (allInputsBlock) => {
      let isHave = false;
      for (let i = 0; i < allInputsBlock.length; i++) {
        const block = allInputsBlock[i];

        let textareas = $(block).find("textarea");

        textareas.map(function () {
          if ($(this).val() == "") {
            isHave = true;
          }
        });
      }

      return isHave;
    };

    $(".ComplexList").map(function () {
      let element = $(this);
      let children = element.children();

      let titleBlock = children[0];
      let title = titleBlock.innerHTML;

      let allElementsBlock = children[1];
      let allInputsBlock = $(allElementsBlock).find(".inputsBlock");

      let isHave = haveEmpty(allInputsBlock);

      if (isHave === true) titles.push(title);
    });

    return titles;
  };

  const scrollToFirstInList = () => {
    const firstTitle = missed[0];

    let titlesBlock = $(".ElementContainer").find(".d-flex.flex-row.m-0.bold");

    let topOffset;
    titlesBlock.map(function () {
      const block = this;

      const blockTitle = block.innerHTML;
      if (blockTitle == firstTitle) {
        topOffset = $(block).offset().top;
      }
      console.log(topOffset);
      // const { top } = $(block).offset();
      // console.log(top);
    });

    if (isValid(topOffset)) {
      var body = $("html, body");
      body
        .stop()
        .animate({ scrollTop: topOffset }, 500, "swing", function () {});
    }
  };

  const isEmptyMissed = missed.length == 0;

  const handleClickAccept = () => {
    if (isShowWarning) {
      onAccept();
      return;
    }
    setIsShowWarning(true);
  };

  return (
    <div className="overlay">
      <div className="wrapper">
        <div className="form">
          <div className="title-row">
            <div className="title">Внимание!</div>
            <div className="close-container">
              <div className="close" onClick={closeModal}></div>
            </div>
          </div>
          <div className="missed">
            {isEmptyMissed ? null : (
              <>
                <div className="ignoreAlert before">{ignoreAlert.before}</div>
                <MissedFields fields={missed}></MissedFields>
                <div className="ignoreAlert after">{ignoreAlert.after}</div>

                <div
                  className={isShowWarning ? "button ignore" : "button return"}
                  onClick={onBackClick}
                >
                  Продолжить заполнение анкеты сейчас
                </div>
              </>
            )}
            <div
              className="button ignore"
              style={{ margin: 0 }}
              onClick={onSave}
            >
              Сохранить и заполнить позже
            </div>
            {isShowWarning ? (
              <div className={styles.warning}>
                Внимание! При нажатии данной кнопки редактировать данные анкеты
                будет невозможно
              </div>
            ) : null}

            <div
              className={isShowWarning ? "button return" : "button ignore"}
              onClick={handleClickAccept}
            >
              Использовать указанные в анкете сведения. Полноту и достоверность
              подтверждаю
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissedFieldsForm;
