import React from "react";
import { Redirect } from "react-router-dom";
import text from "../language/errors";
import { H } from "../Constants";

const Error = () => {
  return <Redirect to={H.main}></Redirect>;
};

export default Error;
