import React, { useState } from "react";

import { Link } from "react-router-dom";

import { H } from "../../Constants";

import { T, editOprosnikText } from "../../language/ru";

import "./index.scss";

const copyStatuses = {
  default: "DEFAULT",
  success: "SUCCESS",
  fail: "FAIL",
};
export const ScenarioCard = ({
  Id,
  Title,
  deleteScenario,
  CategoryId,
  search = "",
  uuid = "",
}) => {
  const [copyStatus, setCopyStatus] = useState(copyStatuses.default);

  const changeCopyStatus = (status, time) => {
    setCopyStatus(status);
    setTimeout(() => {
      setCopyStatus(copyStatuses.default);
    }, time);
  };
  const copySuccess = () => changeCopyStatus(copyStatuses.success, 1000);
  const copyFail = () => changeCopyStatus(copyStatuses.fail, 6000);

  const hrefForCustomer = H.oprosnik + "/" + Id;
  const hrefToEditScenario = H.script + ":" + CategoryId + "&scenarioId:" + Id;

  const handleWriteUUIDToClipboard = () => {
    let writePromise = navigator.clipboard.writeText(uuid);

    writePromise.then(copySuccess).catch(copyFail);
  };

  Id = `#${Id} `;

  const isCopyDefault = copyStatus == copyStatuses.default;
  const isCopySuccess = copyStatus == copyStatuses.success;
  const isCopyFail = copyStatus == copyStatuses.fail;
  return (
    <div className="script">
      <div className="script__title">
        <span className="title__number">{Id}</span>
        {Title}
      </div>
      <div className="script__actions">
        <Link className="actions__get-docs" to={hrefForCustomer}>
          {T.getDocuments}
        </Link>

        <Link className="actions__edit" to={hrefToEditScenario}>
          {editOprosnikText}
        </Link>
        {uuid ? (
          <div
            className="actions__edit"
            style={{ position: "relative" }}
            onClick={handleWriteUUIDToClipboard}
          >
            UUID
            {isCopySuccess ? (
              <span className="actions__alert success">
                Успешно скопировано
              </span>
            ) : null}
            {isCopyFail ? (
              <span className="actions__alert fail">
                Не удалось скопировать UUID
                <br /> {uuid}
              </span>
            ) : null}
          </div>
        ) : null}

        <div className="actions__delete" onClick={deleteScenario}>
          {T.deleteOprosnik}
        </div>
      </div>
    </div>
  );
};
