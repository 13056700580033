import React from "react";

import styles from "./Input.module.scss";

export const Input = ({
  onChange = () => {},
  value = "",
  name = "",
  placeholder = "",
  ...props
}) => (
  <input
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    name={name}
    className={styles.input}
    {...props}
  />
);
