import React from "react";
import { Link } from "react-router-dom";

const CategoryCardLink = ({
  linkInfo: { getPathname = "", text = "", className = "" },
  Id,
  scenarioId,
  templateTitles,
}) => (
  <Link
    className={className}

    to={{
      pathname: getPathname(Id, scenarioId),
      query: {
        templates: templateTitles,
      },
    }}
  >
    {text}
  </Link>
);

export default CategoryCardLink;
