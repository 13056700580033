import React from "react";
import { useState } from "react";

var Section = ({ children, title, className }) => {
  const [state, setState] = useState({ open: false, class: "section" });

  const handleClick = () => {
    if (state.open) {
      setState({
        open: false,
        class: "section",
      });
    } else {
      setState({
        open: true,
        class: "section open",
      });
    }
  };

  return (
    <div className={state.class}>
      <button>toggle</button>
      <div className="sectionhead" onClick={handleClick}>
        {title}
      </div>
      <div className={`articlewrap ${className}`}>
        <div className="article">{children}</div>
      </div>
    </div>
  );
};

export const Accordion = ({ title, sections = [] }) => (
  <div className="main">
    {/* <div className="title">{title}</div> */}
    {sections.map(({ title, text, className }) => {
      return (
        <Section className={className} title={title}>
          {text}
        </Section>
      );
    })}
  </div>
);
