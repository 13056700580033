import React from "react";

import styles from "./Select.module.scss";

export const Select = ({ onChange, options = [], value, placeholder = "" }) => (
  <select className={styles.select} onChange={onChange}>
    <option value={null} selected={value == null}>
      {placeholder}
    </option>
    {options.map((option) => (
      <option value={option.Id} selected={option.Id == value} key={option.Id}>
        {option.Title}
      </option>
    ))}
  </select>
);
