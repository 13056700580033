const initialState = "";

export default function error(state = initialState, action) {
  if (action.type === "ERROR") {
    return action.code;
  } else if (action.type === "CLEAR_ERROR") {
    return "";
  }
  return state;
}
