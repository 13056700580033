
const initialState = "";

export default function tokens(state = initialState, action) {
  if (action.type === "LOAD_TOKENS") {
    const { type } = action.payload;
    
    localStorage.setItem("type", type);
    localStorage.setItem("token", JSON.stringify(action.payload));
    return action.payload;
  }
  return state;
}

