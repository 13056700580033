import React from "react";

const MissedField = ({ field }) => {
  return <div className="MissedField">{field}</div>;
};

const MissedFields = ({ fields }) => {
  return fields.map((field, index) => {
    return <MissedField field={field} key={index}></MissedField>;
  });
};

export default MissedFields;
