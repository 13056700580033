import React, { Children } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { isValid } from "../Constants";
import TitleRow from "./TitleRow";

export default class ScenarioScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      selectedOptions: [],
    };
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      elements: newProps.elements,
    });
  }

  componentDidMount() {
    this.setState({
      elements: this.props.elements,
    });
  }

  elementUp = (index) => {
    var tmpEl = this.state.elements[index];
    var tmpElements = this.state.elements;
    tmpElements[index] = this.state.elements[index - 1];
    tmpElements[index - 1] = tmpEl;
    this.props.changeOrder(this.props.parentId, tmpElements);
  };
  elementDown = (index) => {
    var tmpEl = this.state.elements[index];
    var tmpElements = this.state.elements;
    tmpElements[index] = this.state.elements[index + 1];
    tmpElements[index + 1] = tmpEl;
    this.props.changeOrder(this.props.parentId, tmpElements);
  };
  render() {
    const { elements } = this.state;
    return (
      <div className="ElementContainer">
        {elements.map((element, index) => this.renderElement(element, index))}
      </div>
    );
  }

  checkBoxChange = (e, Title, option) => {
    var elementsValues = this.props.elementsValues;
    var checkedOptions = elementsValues[Title];
    if (checkedOptions != null) {
    } else {
      checkedOptions = [];
    }
    checkedOptions.includes(option)
      ? checkedOptions.splice(checkedOptions.indexOf(option), 1)
      : checkedOptions.push(option);

    this.props.valueChange(Title, checkedOptions /*.toString()*/);
  };

  handleChange = (value, Title) => {
    this.props.valueChange(Title, value);
  };
  isActive = (isActive) => {
    return "labelRadio " + (isActive ? "activeLabel" : "");
  };
  renderElement = (element, index) => {
    if (typeof element === "undefined" || element === null) {
      return;
    }
    const { selectedOptions } = this.state;
    const { elementsValues } = this.props;
    const { Type, Title, Struct, Id, children } = element;
    var struct = [];
    var inputKeys = [];
    var keys = [];
    var title = "Поле для ввода";
    if (Type == 6) title = "Выбор";
    if (Type == 7) title = "Список";
    if (Type == 8) title = "Сложный список";
    if (Type == 8 && typeof JSON.parse(Struct).struct != "undefined")
      keys = Object.keys(JSON.parse(Struct).struct[0]);

    var { displayedName, tip } = this.props.inputs[Title];
    return (
      <>
        <div key={index} className="Element">
          <div className="d-flex flex-column w-100">
            <div className="TitleRow title">
              <TitleRow
                struct={Struct}
                top={index === 0}
                bottom={this.state.elements.length - 1 === index}
                title={title}
                down={() => this.elementDown(index)}
                up={() => this.elementUp(index)}
              ></TitleRow>
              <div className="title">{Title}</div>
            </div>
            <input
              name={Title}
              onChange={(e) => {
                this.props.changeInput(e.target.value, Title, "displayedName");
              }}
              value={displayedName}
              placeholder="Вопрос для пользователя"
            />
            <input
              className="tip-input"
              name={"Подсказка"}
              onChange={(e) => {
                this.props.changeInput(e.target.value, Title, "tip");
              }}
              value={tip}
              placeholder="Подсказка"
            />
            {Type == 8 ? (
              <>
                <div className="d-flex flex-column mb-1">
                  <div className="d-flex flex-column mb-1">
                    {keys.map((key, index) => (
                      <div className="complexListKey" key={index}>
                        <label>{key}</label>
                        <input
                          onChange={(e) => {
                            this.changeInputType8(
                              e,
                              Title,
                              key,
                              "displayedName"
                            );
                          }}
                          value={
                            JSON.parse(Struct).struct[0][key].displayedName
                          }
                          placeholder="Вопрос для пользователя"
                        />
                        <input
                          onChange={(e) => {
                            this.changeInputType8(e, Title, key, "tip");
                          }}
                          value={JSON.parse(Struct).struct[0][key].tip}
                          placeholder="Подсказка"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
            {Type == 6 ? (
              <>
                <div className="RadioElement">
                  {JSON.parse(Struct).struct.map((input, i) => {
                    return (
                      <div key={i} className="form-check">
                        <label
                          className={
                            /*(this.isActive(
                            this.props.elementsValues[Title] == input
                            )) + */ " radio1"
                          }
                          htmlFor={Title + "" + input}
                        >
                          <input
                            id={Title + "" + input}
                            type="radio"
                            style={{ display: "none" }}
                            value={input}
                            checked={this.props.elementsValues[Title] == input}
                            onChange={(e) =>
                              this.handleChange(e.target.value, Title)
                            }
                            className="hidden"
                          />
                          <span class="label"></span>
                          {input}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}

            {Type == 7 ? (
              <>
                <div className="RadioElement checkbox">
                  {JSON.parse(Struct).struct.map((option, i) => {
                    return (
                      <div key={i} className="form-check">
                        <label
                          className={"d-flex align-items-center"}
                          htmlFor={Title + "" + option}
                        >
                          <input
                            id={Title + "" + option}
                            type="checkbox"
                            style={{ display: "none" }}
                            value={option}
                            checked={
                              this.props.elementsValues[Title] != null &&
                              this.props.elementsValues[Title].includes(option)
                            }
                            onChange={(e) =>
                              this.checkBoxChange(e, Title, option)
                            }
                          // className='form-check-input'
                          />
                          <span class="cbx">
                            <svg width="12px" height="12px" viewBox="0 3 12 12">
                              <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                            </svg>
                          </span>
                          <span>{option}</span>
                        </label>
                        {/* <input
                          id={Title + '' + option}
                          type='checkbox'
                          style={{ display: 'none' }}
                          value={option}
                          checked={
                            elementsValues[Title] != null &&
                            elementsValues[Title]
                              // .split(',')
                              .includes(option)
                          }
                          onChange={e =>
                            this.checkBoxChange(e, Title, option)
                          }
                          className='form-check-input'
                        />
                        <label
                          className={this.isActive(
                            elementsValues[Title] != null &&
                            elementsValues[Title]
                              // .split(',')
                              .includes(option)
                          )}
                          htmlFor={Title + '' + option}
                        >
                          {option}
                        </label> */}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}

            {Type == 4 ? (
              <>
                <input
                  name={Id}
                  style={{ display: "none" }}
                  onChange={(e) => this.handleChange(e.target.value, Title)}
                  value={
                    this.props.elementsValues[Title]
                      ? this.props.elementsValues[Title]
                      : ""
                  }
                  placeholder={Title}
                />
                <div className="RadioElement checkbox">
                  {children.map((child) =>
                    typeof child.value !== "undefined"
                      ? this.textInutButtons(element, child)
                      : null
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
        {children.map((child) =>
          (child.elements.length !== 0 &&
            this.conditionIsTrue(
              [this.props.elementsValues[Title]],
              child.value,
              child.operatorType
            )) ||
            (Type == 7 &&
              typeof this.props.elementsValues[Title] !== "undefined" &&
              this.props.elementsValues[Title] !== null &&
              this.conditionCheckBoxIsTrue(
                this.props.elementsValues[Title],
                child.value,
                child.operatorType
              ))
            ? this.renderElements(child.elements, Id)
            : null
        )}
      </>
    );
  };

  textInutButtons = (element, child) => {
    let { Struct = [] } = element;
    Struct = JSON.parse(Struct);
    let struct;
    if (Struct !== null && typeof Struct !== "undefined")
      if (Struct.length > 0) {
        struct = Struct.struct;
      }
    if (typeof struct === "undefined" || struct == null) {
      struct = [];
    }

    const { Title } = element;
    const { value, operatorType } = child;
    const { elementsValues } = this.props;
    // console.log('elementsValues', Title, elementsValues[Title])
    var inputValue = value;
    if (operatorType == "1") inputValue++;
    if (operatorType == "2") inputValue--;
    return (
      <div className="form-check">
        <label
          className={"d-flex align-items-center"}
          htmlFor={Title + "" + inputValue}
        >
          <input
            id={Title + "" + inputValue}
            type="checkbox"
            style={{ display: "none" }}
            value={inputValue}
            checked={
              this.conditionIsTrue([elementsValues[Title]], value, operatorType)
              // this.props.elementsValues[Title] != null &&
              // this.props.elementsValues[Title]
              //   .includes(value)
            }
            onChange={(e) =>
              this.handleChange(
                this.conditionIsTrue(
                  [elementsValues[Title]],
                  value,
                  operatorType
                )
                  ? ""
                  : inputValue,
                Title
              )
            }
          />
          <span class="cbx">
            <svg width="12px" height="12px" viewBox="0 3 12 12">
              <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
            </svg>
          </span>
          <span>
            {"Если "} {operatorType == 0 ? "=" : operatorType == 1 ? ">" : "<"}{" "}
            {value}
          </span>
        </label>
      </div>

      // <>
      //   {/* <input
      //     id={Title + '' + value}
      //     type='radio'
      //     style={{ display: 'none' }}
      //     value={inputValue}
      //     onChange={e => {
      //       console.log(inputValue);
      //       this.handleChange(e, Title)
      //     }}
      //     className='form-check-input'
      //   /> */}
      //   <label
      //     onClick={() => {
      //       this.handleChange(inputValue, Title)
      //     }}
      //     style={{
      //       display:
      //         typeof value != '5460' && typeof operatorType != 'undefined'
      //           ? 'block'
      //           : 'none'
      //     }}
      //     className={this.isActive(this.conditionIsTrue([elementsValues[Title]], value, operatorType)
      //       //    typeof elementsValues[Title] === "string" && (
      //       //    elementsValues[Title] == value && operatorType == 0
      //       // || Number(elementsValues[Title]) > Number(value) && operatorType == 1
      //       // || Number(elementsValues[Title]) < Number(value) && operatorType == 2
      //       // )
      //     )}
      //   // htmlFor={Title + '' + value}
      //   >
      //     {'Если '} {operatorType == 0 ? '=' : operatorType == 1 ? '>' : '<'}{' '}
      //     {value}
      //   </label>
      // </>
    );
  };
  conditionCheckBoxIsTrue = (currentValue, value, operatorType) => {
    return currentValue.includes(value);
  };
  conditionIsTrue = (currentValue, value, operatorType) => {
    // console.log("12345678     ", currentValue, value, operatorType);
    let flag = false;
    if (currentValue != null) {
      currentValue /*.split(',')*/
        .forEach((val) => {
          if (
            val == "true" ||
            (operatorType == 0 && val == value) ||
            (operatorType == 1 && parseInt(val) > parseInt(value)) ||
            (operatorType == 2 && parseInt(val) < parseInt(value))
          )
            flag = true;
        });
    }
    return flag;
  };
  changeOrder = (parentId, newOrder) => {
    let bufElements = this.state.elements;
    if (parentId !== null) {
      let parentIndex = bufElements.findIndex(
        (element) => element.Id == parentId
      );
      bufElements[parentIndex].children[0].elements = newOrder;
    }
    this.props.changeOrder(this.props.parentId, bufElements);
  };
  changeInputType8 = (e, Title, key, fieldName) => {
    let { value } = e.target;

    let bufElements = this.state.elements;
    console.log(bufElements);
    let elementIndex = bufElements.findIndex((element) =>
      isValid(element) ? element.Title == Title : null
    );

    let Struct = JSON.parse(bufElements[elementIndex].Struct);
    Struct.struct[0][key][fieldName] = value;

    bufElements[elementIndex].Struct = JSON.stringify(Struct);

    this.props.changeOrder(this.props.parentId, bufElements);
  };
  changeInput = (e, Title, fieldName) => {
    let bufElements = this.state.elements;
    let elementIndex = bufElements.findIndex(
      (element) => element.Title == Title
    );
    bufElements[elementIndex][fieldName] = e.target.value;
    this.props.changeOrder(this.props.parentId, bufElements);
  };
  renderElements = (children, elementId) => {
    return (
      <ScenarioScreen
        elements={children}
        valueChange={this.props.valueChange}
        elementsValues={this.props.elementsValues}
        parentId={elementId}
        changeOrder={this.changeOrder}
        inputs={this.props.inputs}
        changeInput={this.props.changeInput}
      />
    );
  };
}
