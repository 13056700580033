import React from "react";

import "./CategoryCardTemplatesList.scss";
import TemplateRow from "./TemplateRow";

const CategoryCardTemplatesList = ({
  templates = [],
  fileUploadSuccess = () => {},
  onTemplateDelete = () => {},
}) =>
  templates.map((template) => (
    <TemplateRow
      key={template.Id}
      template={template}
      fileUploadSuccess={fileUploadSuccess}
      onTemplateDelete={() => onTemplateDelete(template.Id)}
    />
  ));

export default CategoryCardTemplatesList;
