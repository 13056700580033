import React, { useEffect, useState } from "react";

import {
  createPromocode,
  deletePromocode,
  getAllPromocodes,
} from "../api/methods";

import { CreateInput } from "../component/UI-kit/CreateInput";

import sha1 from "js-sha1";

export const Promocodes = () => {
  const [promocodes, setPromocodes] = useState([]);

  useEffect(() => {
    loadPromocodes();
  }, []);

  const loadPromocodes = () =>
    getAllPromocodes(({ data }) => setPromocodes(data));

  const addPromocode = (name, discount) => {
    createPromocode({ name, discount }, loadPromocodes);
  };

  const handleDelete = (id) => {
    deletePromocode({ id }, loadPromocodes);
  };

  return (
    <div className="categories container" style={{ marginTop: 100 }}>
      <CreateInput
        onSubmit={addPromocode}
        placeholder="Название промокода"
        proccessName="Добавить промокод"
      ></CreateInput>
      {promocodes.map(({ id, name, discount }) => (
        <div key={id}>
          {name} – {discount}%
          <span onClick={() => handleDelete(id)} style={{ marginLeft: 20 }}>
            &times;
          </span>
        </div>
      ))}
    </div>
  );
};
