import React, { useState } from "react";

const CategoryItem = ({ c: { Title = "" }, deleteC }) => {
  return (
    <div className="CategoryItem">
      {Title}
      <span className="del" onClick={deleteC}>
        удалить комплект
      </span>
    </div>
  );
};

export default CategoryItem;
