import React from "react";
import { useState } from "react";
import { sendMail } from "../../api/methods";
import { Accordion } from "../UI-kit/Accordion";

const sendingStatuses = {
  initial: 0,
  loading: 1,
  success: 2,
  error: 3,
};

export const TitleInfo = ({ qTitle, isAdmin = false }) => {
  const qId = document.location.pathname.split("/").pop();
  const [state, setState] = useState({ email: "" });
  const [sendStatus, setSendStatus] = useState(sendingStatuses.initial); // "INITIAL" "LOADING" "SUCCESS" "ERROR"
  const [wasError, setWasError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const sendEmail = () => {
    setSendStatus(sendingStatuses.loading);
    sendMail(
      { id: qId, email: state.email },
      (res) => {
        setSendStatus(sendingStatuses.success);
      },
      (err) => {
        setSendStatus(sendingStatuses.error);
        setWasError(true);
      }
    );
  };

  const isDeclaration = qTitle.includes("Декларац");

  const complectSections = [
    {
      className: "first",
      title:
        "Какая документация по объекту может потребоваться для заполнения опросного листа?",
      text: (
        <div>
          <p>1. Проект АПС.</p>
          <p>2. Проект СОУЭ.</p>
          <p>3. Техническая документация.</p>
          <p>
            4. Проект строительства (реконструкции, технического перевооружения,
            капитального ремонта).
          </p>
          <p>5. Технический паспорт БТИ.</p>
          <p>6. Штатное расписание.</p>
          <p>7. График работы.</p>
          <div>
            8. Расчет категорий помещений по взрывопожарной и пожарной
            опасности.
          </div>
        </div>
      ),
    },
    {
      className: "second",
      title: "Что если вы не знаете ответ на какой-либо из вопросов?",
      text: (
        <div>
          Если на данный момент вы затрудняетесь внести все данные, то можно
          пока пропустить «проблемное» поле и потом направить дополнительно
          информацию на email <a href="mailto:info@gotdoc.ru">info@gotdoc.ru</a>
          .
        </div>
      ),
    },
    {
      className: "third",
      title: "Когда будет готов комплект?",
      text: (
        <div>
          <p>Порядок подготовки документов следующий:</p>
          <p>
            1. Заполните опросный лист. На ваш e-mail придут оферта и счет для
            оплаты.
          </p>
          <p>
            2. Оплатите счет. Для ускорения процесса можно направить платежку
            или чек.
          </p>
          <div>
            3. Получите готовый комплект. В течение 1-2 суток после оплаты он
            поступит на ваш e-mail.
          </div>
        </div>
      ),
    },
  ];

  const declarationSections = [
    {
      className: "first",
      title:
        "Какая документация по объекту может потребоваться для заполнения опросного листа?",
      text: (
        <div>
          <p>
            1. Проектная документация строительства (реконструкции, технического
            перевооружения, капитального ремонта) раздел 9 «Мероприятия по
            обеспечению пожарной безопасности».
          </p>
          <p>2. Проекты СПЗ (системы противопожарной защиты)</p>
          <p>3. Технический паспорт БТИ.</p>
          <p>
            4. Расчет категорий помещений по взрывопожарной и пожарной
            опасности.
          </p>
          <p>
            5. Последнее предписание ГПН с отметками о выполнении
            противопожарных мероприятий.
          </p>
          <div>6. Отчет по расчету пожарного риска.</div>
        </div>
      ),
    },
    {
      className: "second",
      title: "Что если вы не знаете ответ на какой-либо из вопросов?",
      text: (
        <div>
          Если на данный момент вы затрудняетесь внести все данные, то можно
          пока пропустить «проблемное» поле и потом направить дополнительно
          информацию на email <a href="mailto:info@gotdoc.ru">info@gotdoc.ru</a>
          .
        </div>
      ),
    },
    {
      className: "third",
      title: "Когда будет готов документ?",
      text: (
        <div>
          <p>Порядок подготовки следующий:</p>
          <p>
            1. Заполните опросный лист. На ваш e-mail придут оферта и счет для
            оплаты.
          </p>
          <p>
            2. Оплатите счет. Для ускорения процесса можно направить платежку
            или чек.
          </p>
          <div>
            3. Получите готовую декларацию пожарной безопасности. В течение 1-2
            суток после оплаты она поступит на ваш e-mail.
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="header">
        <a className="logotype" href="https://gotdoc.ru/">
          <img src="/img/logo.jpg"></img>
        </a>
        <a className="tel" href="tel:88005504908">
          8 (800) 550-49-08
        </a>
      </div>
      <div className="TitleBlock">
        <p className="darkgray" style={{ marginBottom: 0, fontSize: 26 }}>
          Анкета
        </p>
        <h1>
          {qTitle == "" ? "" : "«" + qTitle + "»‎"} <br />
          Ответьте, пожалуйста, на несколько вопросов.
        </h1>
        {isAdmin ? null : (
          <>
            <p>
              Для заказа{" "}
              {isDeclaration ? "декларации " : "комплекта документов по "}
              пожарной безопасности заполните опросный лист по вашему объекту.
            </p>
            <p className="darkgray">
              Пожалуйста, будьте внимательны при ответе на вопросы. Эти данные
              необходимы для того, чтобы учесть все нюансы и детально
              проработать информацию{isDeclaration ? "" : " для комплекта"}!
            </p>
          </>
        )}
        {isAdmin ? null : sendStatus == sendingStatuses.success ? (
          <p
            style={{
              color: "rgb(78, 152, 11)",
              marginTop: 5,
              marginBottom: 25,
            }}
            className=" text-center"
          >
            Ваше письмо было успешно отправлено!
          </p>
        ) : (
          <div className="emailForm">
            <div style={{ display: "flex" }}>
              <textarea
                name="email"
                value={state.email}
                onChange={handleChange}
                placeholder="Введите ваш e-mail"
              ></textarea>
              <div
                onClick={
                  sendStatus == sendingStatuses.loading ? null : sendEmail
                }
                style={{
                  backgroundColor:
                    sendStatus == sendingStatuses.loading
                      ? "#afafaf"
                      : "#48b1fd",
                  width: 141,
                }}
                className="Upload"
              >
                {sendStatus == sendingStatuses.loading
                  ? "Загрузка"
                  : "Отправить"}
              </div>
            </div>
            <p
              style={{ color: "#c4c4c4", marginTop: 5 }}
              className=" text-center fz-14"
            >
              Отправьте ссылку на опросный лист на свой e-mail, чтобы заполнить
              его в удобное время
            </p>
            {sendStatus == sendingStatuses.error || wasError ? (
              <p
                style={{ color: "#c4c4c4", marginBottom: 25 }}
                className=" text-center fz-14"
              >
                Возникла ошибка при отправке письма. Пожалуйста, проверьте
                правильность электронного адреса или попробуйте позже.
              </p>
            ) : null}
          </div>
        )}

        {isAdmin ? null : (
          <Accordion
            sections={isDeclaration ? declarationSections : complectSections}
          ></Accordion>
        )}
        <div
          style={{
            marginTop: 50,
            padding: "24px 40px 24px 40px",
            border: "1px solid #eee",
          }}
        >
          <p>
            <b style={{ fontSize: 30 }}>Уважаемый Пользователь! </b>
            <br></br>
            При заполнении анкеты пользуйтесь, пожалуйста, примерами (выделены
            <span style={{ color: "#48b1fd", fontWeight: 500 }}>
              {" "}
              синим{" "}
            </span>{" "}
            цветом) и вносите информацию аналогичным образом. Это необходимо для
            корректного составления документов.
          </p>
        </div>
      </div>
    </>
  );
};
