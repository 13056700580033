import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import text from "../language/script";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import ScenarioScreen from "../component/ScenarioScreen";
import { apiUrl, isValid, H, nameForHideStruct } from "../Constants";
import scriptFunctions from "../commonFunctions/scriptFunctions";
import { Loader } from "../component/UI-kit/Loader/Loader";

class ScriptScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      screens: [[]],
      selectedOptions: [],
      currentScreen: 1,
      scenarioName: "",
      elementsValues: [],
      docIds: [],
      uploadingStatus: "DEFAULT",
      isAllElementsDownload: false,
    };
  }

  elementUp = (index) => this.moveElement(index, -1)

  elementDown = (index) => this.moveElement(index, 1)

  moveElement = (index, diff) => {
    let tmpElements = this.state.elements;
    let tmpEl = tmpElements[index];

    tmpElements[index] = this.state.elements[index + diff];
    tmpElements[index - diff] = tmpEl;

    this.setState({
      elements: tmpElements,
    });
  }


  getTemplate = async (id) => {
    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );
    console.log(Refresh_token);
    let data = JSON.stringify({
      JWT: Access_token,
      categoryId: id,
    });
    let url = apiUrl + "?class=Template&action=getTemplates";
    let resp = await Axios({
      url,
      method: "post",
      data,
    });
    let docIds = resp.data.map((template) => {
      return template.Id;
    });
    console.log("doc IDs", docIds);
    this.state.docIds = docIds;
    return docIds;
  };
  removeDuplicates(originalArray, prop, dependentDocs = []) {
    let newArray = [];
    let lookupObject = {};
    console.log("Зависымые доки", dependentDocs);
    // TODO
    // Здесь должна быть остальная логика при удалении дубликатов, например, кто побеждает при разном количестве условий и ТД
    originalArray.forEach((item, index) => {
      // В объект lookupObject засовывается последний объект с названием по пути originalArray[index][prop]
      // Из всех копий надо оставить тот объект, что находится в независимом доке — приоритет независимого дока

      let currentElement = lookupObject[originalArray[index][prop]];
      let compareElement = originalArray[index];
      // если объект уже находится в массиве lookupObject
      if (typeof currentElement !== "undefined") {
        // Если compare в независмом — засунуть
        if (dependentDocs.includes(compareElement.TemplateId) === false) {
          lookupObject[originalArray[index][prop]] = originalArray[index];
        } else {
          // Если текущий объект в массиве находится в независмом (и compare — в зависимом(из предыдущего)), то ничего не менять
          // if(dependentDocs.includes(currentElement.templateId) )
        }
      } else {
        lookupObject[originalArray[index][prop]] = originalArray[index];
      }
    });

    let arr = [lookupObject];
    console.log("Наблюдаемый объект в массиве элементов", [...arr]);

    Object.keys(lookupObject).forEach((element) => {
      newArray.push(lookupObject[element]);
    });
    return newArray;
  }

  getElementsByScenario = async (scenarioId) => {
    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );
    console.log(Refresh_token);
    let url =
      apiUrl + "?class=Scenario&action=getScenario&scenarioId=" + scenarioId;
    let resp = await Axios({
      url,
      method: "get",
    });

    let elements = JSON.parse(resp.data[0]["JSON"]);
    let scenarioName = resp.data[0].Title;
    if (scenarioName) this.setState({ scenarioName });
    console.log("Response", elements);

    let inputs = {};
    const elementsWithoutRecursive = [];
    elements.map((element, index) => {
      this.loadInputs(inputs, element, index, elementsWithoutRecursive);
    });
    console.log("Inputs", inputs);

    return [inputs, elementsWithoutRecursive];
  };

  // Заполняем массив inputs
  loadInputs = (inputs, element, index, elementsWithoutRecursive = []) => {
    if (isValid(element)) {
      const { Title, displayedName = "", tip = "", children } = element;
      elementsWithoutRecursive.push(element);
      inputs[Title] = { displayedName, tip, index };
      children.map((child) => {
        const { elements } = child;
        elements.map((childElement, i) => {
          this.loadInputs(inputs, childElement, i, elementsWithoutRecursive);
        });
      });
    }
  };

  getAllElements = async () => {
    let categoryId = document.location.pathname.split(":")[1];
    categoryId = categoryId.replace("&scenarioId", "");

    let scenarioId = document.location.pathname.split(":")[2];
    console.log("CategoryId", categoryId, "ScenarioId", scenarioId);

    const allElements = [];

    let inputsWithDataFromScenario = {};

    let elementsFromScenarioWithoutRecursive = [];

    if (typeof scenarioId !== "undefined" && scenarioId != 0) {
      [inputsWithDataFromScenario, elementsFromScenarioWithoutRecursive] =
        await this.getElementsByScenario(scenarioId);
    }

    console.log(
      "Элементы без рекурсии из сценария",
      elementsFromScenarioWithoutRecursive
    );

    await this.getTemplate(categoryId).then(async (Ids) => {
      for (let index = 0; index < Ids.length; index++) {
        await this.getElements(Ids[index]).then((data) => {
          data.map((element) => {
            const isComplextList = element.Type == 8;
            if (isComplextList)
              this.fillStructFromScenarioElements(
                elementsFromScenarioWithoutRecursive,
                element
              );

            allElements.push(element);
          });
        });
      }
    });
    console.log("Все элементы, где вроде как там что-то поменялось", [
      ...allElements,
    ]);
    return [allElements, inputsWithDataFromScenario];
  };

  // Заполняем подсказки и отображаемые имена для элементов с сервера исходя из инфы с опросника
  fillStructFromScenarioElements = (elsWithoutRecursive = [], clearElement) => {
    const scenarioElement = elsWithoutRecursive.find(
      ({ Title }) => Title === clearElement.Title
    );

    if (!isValid(scenarioElement)) return;

    const { Struct } = scenarioElement;

    const clearElementStruct = clearElement.Struct;

    if (isValid(Struct) && isValid(clearElementStruct)) {
      const { struct } = JSON.parse(Struct);
      const structOfScenarioElement = struct[0];

      const parsedClearElementStruct = JSON.parse(clearElementStruct);
      const structOfClearEl = parsedClearElementStruct.struct;

      let newStruct = {};

      structOfClearEl.map((key) => {
        console.log("ключ", key);
        const foundedStruct = structOfScenarioElement[key];
        if (foundedStruct) {
          const { displayedName, tip } = foundedStruct;
          newStruct = {
            ...newStruct,
            [key]: { displayedName, tip },
          };
        } else {
          newStruct = {
            ...newStruct,
            [key]: { displayedName: "", tip: "" },
          };
        }
      });

      parsedClearElementStruct.struct = [newStruct];
      clearElement.Struct = JSON.stringify(parsedClearElementStruct);
    }
  };

  findIndexByTemplateId = (elements, TemplateId) => {
    return elements.findIndex((element) => element.TemplateId == TemplateId);
  };

  findIndexByTitle = (elements, Title) => {
    return elements.findIndex((element) => element.Title == Title);
  };

  valueChange = (Title, newValue) => {
    console.log("valueChange", Title, newValue);

    let bufElsVals = this.state.elementsValues;

    bufElsVals[Title] = newValue;

    this.setState({
      elementsValues: bufElsVals,
    });
  };

  changeOrder = (parentId, newOrder) => {
    this.setState({
      elements: newOrder,
    });
  };

  componentDidMount = () => {
    this.getAllElements().then((AllElements) => {
      let [allElements, inputs] = AllElements;

      let elsVals = [];
      let lostDocs = [];
      let dependentDocs = [];

      allElements = allElements.map((element) => {
        const { Title } = element;
        if (typeof inputs[Title] === "undefined" || inputs[Title] == null) {
          inputs[Title] = {
            displayedName: "",
            tip: "",
            index: 999,
          };
        }
        let { Struct } = element;
        Struct = JSON.parse(Struct);
        element.children = [];

        const isElementValidComplexList =
          Struct != null &&
          typeof Struct.struct != "undefined" &&
          element.Type == "8";

        if (isElementValidComplexList) {
          if (Struct.struct) {
            if (typeof Struct.struct[0] !== "object") {
              let inputObj = {};

              Struct.struct.forEach((inputName) => {
                inputObj[inputName] = {
                  value: "",
                  displayedName: "",
                  tip: "",
                };
              });

              Struct.struct = [inputObj];
              element.Struct = JSON.stringify(Struct);
            }
          }
        }

        if (Struct != null && typeof Struct.docs == "undefined") {
          Struct.docs = [];
          element.Struct = JSON.stringify(Struct);
        }

        if (Struct != null && Struct.docs.length != 0) {
          Struct.docs.map((doc) => {
            if (doc.elementName == 7432) {
              console.log("123123", doc, element.Title);
            }
            // Пушим в массив зависимых доков ID этих документов
            dependentDocs.push(doc.elementName);
            if (!this.state.docIds.includes(doc.elementName)) {
              let key =
                typeof doc.docName !== "undefinded"
                  ? doc.docName
                  : doc.elementName;
              if (typeof lostDocs[key] != []) {
                lostDocs[key] = [];
              }
              lostDocs[key].push({
                Title: element.Title,
                TemplateId: element.TemplateId,
              });
            }
          });
        }
        // if (element.Type == 4)
        //   elsVals[element.Title] = ''
        // else
        elsVals[element.Title] = null;
        // if (element.Type == 6) {
        //   elsVals[element.Title] = Struct.struct[0]
        // }
        element.displayedName = "";
        element.neededPosition = false; // Изначально все не на своем месте
        element.tip = "";
        return element;
      });
      let keys = Object.keys(lostDocs);
      console.log("lostDocs", lostDocs);
      // TODO: toodoo
      if (keys.length != 0) {
        const { location } = this.props;
        let query;
        if (isValid(location)) {
          query = location.query;
        }
        let msg = "";
        keys.forEach((key, index) => {
          msg +=
            'Документа "' +
            key +
            '" не существует. Привяжите новый документ в элементе (элементах):\n\n' +
            lostDocs[key].map((el) => {
              return (
                '"' +
                el.Title +
                '" (находится в документе "' +
                (typeof query !== "undefined"
                  ? query.templates[el.TemplateId]
                  : el.TemplateId) +
                '")\n'
              );
            }) +
            "\n\n\n"; //+", который находится в "+ "\n\n\n"
        });
        // alert(msg)
      }

      // Передаем параметры в функцию для удаления дубликатов:
      // 1. Массив объектов, в которых удаляем дупликаты
      // 2. Ключ по которому проводится удаление дупликатов
      // 3. Массив зависимых доков, который ограничит удаления объектов
      allElements = this.removeDuplicates(allElements, "Title", dependentDocs);

      allElements = this.buildData(allElements, inputs);
      this.setState({
        elements: allElements,
        elementsValues: elsVals,
        inputs,
        isAllElementsDownload: true
      });
    });
  };

  addByTitle(ElementsOfData, FindTitle, newChild) {
    for (let i = 0; i < ElementsOfData.length; i++) {
      const { children, Title } = ElementsOfData[i];
      let childElements = children.elements;
      if (Title == FindTitle) {
        childElements.push(newChild);
      }
      for (let cIndex = 0; cIndex < children.length; cIndex++) {
        this.addByTitle(childElements, Title, newChild);
      }
    }
  }
  prepareData = (data) => {
    for (let currentIndex = 0; currentIndex < data.length; currentIndex++) {
      data[currentIndex].children = [{ elements: [] }];
    }
  };

  pushChildrens = (data, childElements, doc) => {
    const { docParentTitle, operatorType, value } = doc;

    let indexOfParent = this.findIndexByTitle(data, docParentTitle);

    if (indexOfParent == -1) {
      let isParentFound = false;

      data.forEach((element) => {
        if (!isParentFound) {
          const { children } = element;

          children.forEach(
            (pool) =>
            (isParentFound = this.pushChildrens(
              pool.elements,
              childElements,
              doc
            ))
          );
        }
      });
    } else {
      let isPoolFound = false;

      const parent = data[indexOfParent];

      const parentChildren = parent.children;

      parentChildren.forEach((pool, poolIndex) => {
        const isPullAndDocOptionsSame =
          pool.value == doc.value && pool.operatorType == doc.operatorType;

        if (isPullAndDocOptionsSame) {
          isPoolFound = true;

          const poolToAddElement = parentChildren[poolIndex].elements;

          const isPoolContainElement = (pool = [], element) =>
            pool.find((poolElement) => poolElement.Id == element.Id);

          const addElementToParentPool = (childElement) => {
            if (!isPoolContainElement(poolToAddElement, childElement))
              poolToAddElement.push(childElement);
          };

          childElements.map(addElementToParentPool);
        }
      });

      if (!isPoolFound) {
        const newPool = {
          value: doc.value,
          operatorType: doc.operatorType,
          elements: childElements,
        };
        parentChildren.push(newPool);
      }
    }
  };

  newFilterByDocs = (docsToHide, data) => {
    docsToHide.forEach((doc, index) => {
      let childElements = [];
      data = data.filter((element) => {
        if (element.TemplateId == doc.docId) {
          childElements.push(element);
          return false;
        }
        return true;
      });
      if (childElements.length > 0)
        this.pushChildrens(data, childElements, doc);
    });
    return data;
  };

  removeByTitles(data, nameForRemove, disableForRemove = []) {
    nameForRemove = nameForRemove.filter(
      (v, i) => nameForRemove.indexOf(v) === i
    );
    disableForRemove = disableForRemove.filter(
      (v, i) => disableForRemove.indexOf(v) === i
    );
    nameForRemove = nameForRemove.filter((name) => {
      let flag = true;
      disableForRemove.map((d) => {
        if (d == name) flag = false;
      });
      return flag;
    });

    data.filter((e) => {
      let flag = true;
      nameForRemove.map((name) => {
        let deleteIndex = data.findIndex((e) => e.Title === name);
        if (deleteIndex > -1) data.splice(deleteIndex, 1);
      });
      return flag;
    });
  }
  filterByDC = (data) => {
    let nameForRemove = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      let { Struct } = element;
      Struct = JSON.parse(Struct);

      if (Struct == null) continue;
      let parent = null;
      const { displayConditions } = Struct;
      displayConditions.map((DC) => {
        const { elementName, value, operatorType } = DC;
        let elementToMoveIndex = data.findIndex((e) => e.Title == elementName);

        if (elementToMoveIndex > -1) {
          console.log(
            "Элемент для удаления",
            JSON.parse(data[i].Struct).displayConditions
          );
          parent = data[elementToMoveIndex];
          nameForRemove.push(data[i].Title);
        }

        // Если нашли родителя
        if (parent !== null) {
          // Достаем детей
          let { children } = parent;
          // Говорим, что новый бассейн
          let isNewPool = true;
          // Проверяем все бассейны
          for (let j = 0; j < children.length; j++) {
            // Если отсутствует operatorType
            if (typeof children[0].operatorType == "undefined") {
              children[0] = { operatorType, value, elements: [data[i]] };
              isNewPool = false;
            }
            // Если children[j] задан
            else {
              // Если значения текущего DC совпадают с найденным у родителя
              if (
                children[j].operatorType == operatorType &&
                value == children[j].value
              ) {
                // То добавить элемент в выбранный бассейн children
                children[j].elements.push(element); ////////////////////////////////////////////////////////////////////////////
                isNewPool = false;
              }
            }
          }
          // Если таких условий раньше не было то создаем новый бассейн
          if (isNewPool === true) {
            children.push({ operatorType, value, elements: [data[i]] });
          }
        }
      });
    }
    this.removeByTitles(data, nameForRemove);
  };

  chooseForAlwaysShow(data) {
    // Доки, которые зависят от каких-либо условий
    let docIds = [];
    data.map((element) => {
      let { Struct } = element;
      Struct = JSON.parse(Struct);
      if (Struct != null) {
        const { docs = [] } = Struct;
        docs.map((doc) => {
          if (!docIds.includes(doc.elementName)) {
            docIds.push(doc);
          }
        });
      }
    });
    // Сравниваем каждый элемент с каждым
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data.length; j++) {
        const current = data[i];
        const compare = data[j];

        // Первый элемент должен ли быть спрятан ?
        let isCurrentInIds = docIds.includes(current.TemplateId);

        // Второй элемент должен ли быть спрятан ?
        let isCompareInIds = docIds.includes(compare.TemplateId);

        // console.log('Мы тут были  ')
        // Если templateId текущего элемента вне списка docIds, а сравниваемого в нем, то удалить сравниваемый и это один и тот же элемент
        if (
          isCurrentInIds === false &&
          isCompareInIds === true
          // && current.Title == compare.Title
        ) {
          console.log("Прошло по условию", current.Title, compare.Title);

          data.splice(i, 1);
          i--;
        }
      }
    }
  }

  delType5 = (data) => {
    data.forEach((element) => {
      let Struct = JSON.parse(element.Struct);
      if (Struct) {
        if (Struct.parentName) {
          let extEl = element;
          let parent = data[this.findIndexByTitle(data, Struct.parentName)];
          if (
            Struct.displayConditions.length === 0 &&
            typeof parent !== "undefined"
          ) {
            Struct.parentName = null;
            Struct.displayConditions = JSON.parse(
              parent.Struct
            ).displayConditions;
            extEl.Struct = JSON.stringify(Struct);
          }
        }
      }
    });
    data = data.filter((element) => element.Type != "5");
    return data;
  };

  buildData = (elements, inputs) => {
    let data = [...elements];
    let docsToHide = [];

    data.forEach((element) => {
      let Struct = JSON.parse(element.Struct);

      if (Struct != null && typeof Struct.docs != "undefined")
        Struct.docs.forEach((doc) => {
          docsToHide.push({
            docId: doc.elementName,
            value: doc.value,
            operatorType: doc.operatorType,
            docParentTitle: element.Title,
          });
        });
    });
    data = this.delType5(data);

    this.clearComplexListStruct(data);
    this.chooseForAlwaysShow(data);
    this.prepareData(data);
    this.filterByDC(data);

    data = this.newFilterByDocs(docsToHide, data);
    this.setOrder(data, inputs);
    this.copyElementsFromDocsWithORCOndition(data);
    return data;
  };

  // 1) Найти элемент, который привязывет документ

  // 2) Сохранить детей этого элемента всех + id документа
  // 2.1) После нахождения такого элемента вглубь дальше не идти, т.к. все дети копируются
  // Примечение: сохранять пару children + docId в массив

  // 3) Найти элемент, который привязывает этот же документ

  // 4) Дополнить/заменить всех детей этого элемента сохраненными детьми.
  // 4.1) Закончить рекурсию поиска вглубь по такому элементу

  // Прим. Сделать все копии за один раз, иначе рекурсия.

  copyElementsFromDocsWithORCOndition = (data) => {
    const docsAndChildren = [];

    data.forEach((element) =>
      scriptFunctions.findElementWithDocsDocs(element, docsAndChildren)
    );

    console.log("Пары документов + детей", docsAndChildren);
    data.forEach((element) =>
      scriptFunctions.pushChildrenByDocsToElements(element, docsAndChildren)
    );
    console.log(data);
  };

  clearComplexListStruct = (data) => {
    data.forEach((element) => {
      if (!element) return;
      const { Type, Struct } = element;
      let parsedStruct = JSON.parse(Struct);
      if (parsedStruct == null) return;
      let { struct } = parsedStruct;
      const isComplexList = Type == 8;

      if (isComplexList) {
        console.log(`Сложный список ${element.Title} `, struct);

        parsedStruct.struct = this.getClearedStruct(struct, data);
        element.Struct = JSON.stringify(parsedStruct);
      }
    });
  };

  getClearedStruct = (struct, data) => {
    const listElements = struct[0];

    const elementsNames = data.map(({ Title }) => Title);

    const newStruct = {};

    Object.keys(listElements).forEach((key) => {
      if (!elementsNames.includes(key)) {
        console.log("1111", newStruct, listElements);
        newStruct[key] = listElements[key];
      } else {
        newStruct[key] = {
          displayedName: nameForHideStruct,
          tip: "",
          value: "",
        };
      }
    });

    return [newStruct];
  };

  // Меняем элементы в массиве местами.
  // currentIndex - какой он есть в массиве сейчас
  // neededIndex - на каком месте по факту должен стоять элемент
  swapElements(array, currentIndex, neededIndex) {
    /* Если элемент стоит на своем месте - заканчиваем выполнение функции */
    if (currentIndex === neededIndex) {
      array[neededIndex].neededPosition = true;
      return;
    }

    /* Если индекс который нужен - 999 или отсутствует - ставим элемент в конец массива */
    if (
      neededIndex === 999 ||
      typeof neededIndex === "undefined" ||
      neededIndex === null
    ) {
      this.swapElements(array, currentIndex, array.length - 1);
      return;
    }

    // Запоминаем элемент, который прогоним со своего места
    let buf = array[neededIndex];
    // Ставим элемента на свое место
    array[neededIndex] = array[currentIndex];
    // Флаг о том, что текущий элементо и все что внутри больше перемещать не надо
    array[neededIndex].neededPosition = true;
    // Запомненный элемент возвращаем хоть куда-нибудь
    array[currentIndex] = buf;
    return true;
  }
  // Изменить порядок элементов в опроснике
  setOrder = (array, inputs) => {
    for (let currentIndex = 0; currentIndex < array.length; currentIndex++) {
      const element = array[currentIndex];
      // Если элемент существует и находится не на своей позиции
      if (typeof element !== "undefined" && element !== null) {
        if (element.neededPosition === true) continue;

        const { children, Title } = element;
        // Проверяем всех детей текущего объекта (рекурсия)
        children.map((elements) => {
          if (elements.length !== 0) {
            elements = this.setOrder(elements, inputs);
          }
        });
        /* У текущего элемента берем заголовок и находим 
        в массиве inputs нужный индекс и передаем его как neededIndex */
        if (typeof inputs[Title] !== "undefined" && inputs[Title] !== null) {
          let neededIndex = inputs[Title].index;
          console.log(neededIndex, Title);
          let wasSwapped = this.swapElements(array, currentIndex, neededIndex);
          if (wasSwapped === true) currentIndex = 0;
        }
      }
    }
  };
  hideElementsInDoc = (data) => {
    // data.forEach(element => {
    //   JSON.parse(element.Struct).docs
    // })
  };

  getChildrens = (el, childEls) => {
    console.log(
      "start______________________________________________",
      el.Title
    );
    console.log(JSON.parse(el.Struct));
    var element = el;
    var childElements = [...childEls];
    var bufChildElements = [...childEls];
    childElements.map((child) => {
      if (!element.children.includes(child)) {
        var Struct = JSON.parse(child.Struct);
        Struct.displayConditions.map((dc) => {
          if (element.Title == dc.elementName) {
            bufChildElements.splice(
              this.findIndexByTitle(bufChildElements, child.Title),
              1
            );
            console.log("adding by DC", child.Title);
            var elEls = this.getChildrens(child, bufChildElements);
            bufChildElements = [...elEls.bufChildElements];

            element.children.push(elEls.element);
          }
        });
        Struct.docs.map((doc) => {
          var elementsFromDoc = bufChildElements.filter(
            (child) => child.TemplateId == doc.elementName
          );
          console.log("doc pricock: ", doc.elementName, elementsFromDoc);
          elementsFromDoc.map((elementFromDoc) => {
            console.log("adding by doc", elementFromDoc.Title);
            bufChildElements.splice(
              this.findIndexByTitle(bufChildElements, elementFromDoc.Title),
              1
            );
            var elEls = this.getChildrens(elementFromDoc, bufChildElements);
            bufChildElements = [...elEls.bufChildElements];
            element.children.push(elEls.element);
          });
        });
      }
    });
    console.log("end_____________________________________", element.Title);
    return { element, bufChildElements };
  };

  getElements = async (templateID) => {
    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );
    let data = JSON.stringify({
      JWT: Access_token,
      TemplateId: templateID,
    });

    let url = apiUrl + "?class=Template&action=getVariables";
    let response = { data: [] };
    try {
      response = await Axios({
        url,
        method: "post",
        data,
      });
    } catch (error) {
      console.log(error);
    }

    return response.data;
  };

  chooseScreen = (screenIndex) => {
    this.setState({
      currentScreen: screenIndex,
    });
  };
  removeScreen = (screenIndex) => {
    var screens = this.state.screens;
    var elements = this.state.elements;

    const screensCount = screens.length;
    // this.forceUpdate();

    // Если это единственный экран, то его нельзя удалить
    if (screensCount === 1) {
      alert("Должен быть минимум один экран!");
      return;
    }

    // Если экран стоит на последнем месте, то индекс текущего уменьшаем и переносим элементы из экрана в пул
    var deletedElements = screens.splice(screenIndex, 1);
    var n = deletedElements[0].length;
    for (var i = 0; i < n; i++) {
      elements.push(deletedElements[0][i]);
    }
    var newIndex =
      screenIndex === screensCount - 1 ? screenIndex - 1 : screenIndex;
    this.state.currentScreen = 1;

    this.setState(() => ({
      elements: [...elements],
      screens: [...screens],
      currentScreen: 1,
    }));
    this.forceUpdate();
  };

  addScreen = () => {
    var screens = this.state.screens;
    const screensCount = screens.length;

    // Если последний экран до сих пор пустой удаляем его
    if (screens[screensCount - 1].length === 0) return;
    // Иначе разрешаем добавить экран
    screens.push([]);
    this.setState({
      screens,
      currentScreen: screensCount,
    });
  };
  addDisplayedName = (e) => {
    const currentScreen = this.state.currentScreen;
    const screens = [...this.state.screens];
    let index = screens[currentScreen].findIndex(
      (element) => element.Id == e.target.name
    );

    screens[currentScreen][index].displayedName = e.target.value;
    this.setState({ screens });
  };

  startUploading = () => this.setState({ uploadingStatus: "LOADING" });
  finishUploading = () => this.setState({ uploadingStatus: "SUCCESS" });

  uploadQuestionary = (e) => {
    e.preventDefault();
    const { uploadingStatus } = this.state;

    this.startUploading();
    let scenarioId = document.location.pathname.split(":")[2];
    let categoryId = document.location.pathname.split(":")[1];

    // Не меняем существующий
    let isChanging = false;
    // Если мы меняем существующий
    if (typeof scenarioId !== "undefined" && scenarioId != 0) {
      isChanging = true;
    }

    if (isChanging == false && uploadingStatus == "SUCCESS") {
      return;
    }

    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );

    let { scenarioName, elements, inputs } = this.state;
    elements = this.setInputs(elements);

    let url;
    let method;
    let data;
    if (isChanging === true) {
      url = apiUrl + "?class=Scenario&action=changeScenario";
      method = "put";

      data = JSON.stringify({
        scenarioName,
        scenario: JSON.stringify(elements),
        scenarioId,
      });
    } else {
      url = apiUrl + "?class=Scenario&action=createScenario";
      method = "post";
      data = JSON.stringify({
        scenarioName,
        scenario: JSON.stringify(elements),
        categoryId,
      });
    }

    Axios({
      method,
      url,
      data,
      headers: {
        authorization: Access_token,
      },
    }).then((res) => {
      this.finishUploading();
      if (isChanging === true) {
        // alert("Опросник успешно отредактирован!");
        // document.location.replace(H.categories);
      } else {
        // alert("Опросник успешно создан!");
        document.location.replace(H.categories);
      }
    });
  };

  setInputs = (elements) => {
    let { inputs } = this.state;
    return elements.map((el) => {
      console.log("setInput", el);
      if (typeof el === "undefined" || el == null) {
        return el;
      }

      let { Title } = el;
      el.displayedName = inputs[Title].displayedName;
      el.tip = inputs[Title].tip;
      el.children = el.children.map((child) => {
        child.elements = this.setInputs(child.elements);
        return child;
      });
      return el;
    });
  };

  render() {

    const { currentScreen, screens, scenarioName, uploadingStatus, isAllElementsDownload } =
      this.state;

    if (!isAllElementsDownload) {
      return <Loader />
    }

    return (
      <div className="ScriptScreen" style={{ overflowY: "auto" }}>
        <div>
          {this.state.elements.length === 0 ? null : this.renderElements()}
        </div>
        <div className="bxsh">
          <input
            onChange={(e) => this.setState({ scenarioName: e.target.value })}
            value={scenarioName}
            placeholder="Название опросника"
          ></input>
          <div
            style={{ background: uploadingStatus == "LOADING" ? "#ccc" : "" }}
            className="Upload"
            onClick={
              uploadingStatus == "LOADING" ? () => { } : this.uploadQuestionary
            }
          >
            <div className="upload"></div>
            {uploadingStatus == "LOADING" ? "Загрузка.." : "Загрузить опросник"}
          </div>
          {uploadingStatus == "SUCCESS" ? (
            <div
              style={{
                fontWeight: 500,
                color: "#4cce4c",
                marginTop: 10,
                textAlign: "center",
              }}
            >
              Опросник успешно загружен
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  changeInput = (value, Title, fieldName) => {
    let { inputs } = this.state;
    inputs[Title][fieldName] = value;
    console.log("SCRIPTSCREEN: ", inputs);
    this.setState({ inputs });
  };

  handleOptionChange = (e, Id) => {
    var tmpOptions = this.state.selectedOptions;
    tmpOptions[Id] = e.target.value;
    console.log(tmpOptions);
    this.setState({
      selectedOptions: tmpOptions,
    });
  };
  renderElements = () => {
    const { elements, screens } = this.state;
    return (
      <ScenarioScreen
        elements={elements}
        valueChange={this.valueChange}
        elementsValues={this.state.elementsValues}
        changeOrder={this.changeOrder}
        parentId={null}
        inputs={this.state.inputs}
        changeInput={this.changeInput}
      />
    );
  };
}

export default ScriptScreen;
