import React, { useState } from "react";
import { statuses, isValid } from "../Constants";
const Cell = ({ onClick, text, className, label = "" }) => {
  return (
    <div className={"Cell " + className}>
      <div className="label">{label}</div>

      <div className="field" onClick={onClick}>
        {text}
      </div>
    </div>
  );
};

const passStr = (len) => {
  let dot = "●";
  let str = "";

  for (let i = 0; i < 10; i++) {
    str += dot;
  }

  return str;
};

const Option = ({ value, label }) => {
  return <option value={value}>{label}</option>;
};

const Select = ({
  onClick = () => console.log("Ничего не привязали к селекту"),
  options = [],
}) => {
  return (
    <select
      className="Select col-12"
      onChange={(e) => {
        onClick(e.target.value);
      }}
      // onClick={(e) => {
      //   onClick(e.target.value);
      // }}
    >
      <option selected="true" disabled="disabled">
        {"Выберите комплект"}
      </option>
      {options.map((option, index) => {
        const { Id, Title } = option;
        return <Option label={Title} key={index} value={Id}></Option>;
      })}
    </select>
  );
};

const CategoryTab = ({ onClick, label }) => {
  return (
    <div className="CategoryTab ">
      {label}
      <div className="close" onClick={onClick}></div>
    </div>
  );
};

const UserRow = ({
  deleteUser = () => {},
  FIO = "",
  email = "",
  password = "",
  status = "",
  categories = [],
  userCategories = [],
  onClickAddCategory = (categoryId) =>
    console.log("Забыли прокинуть добавление категории"),
  onClickDeleteCategory = (categoryId) =>
    console.log("Забыли прокинуть удаление категории"),
}) => {
  let passwordInDot = passStr(password.length);
  const [showPassword, changeShow] = useState(false);
  return (
    <div className="UserRow">
      <div className="col">
        <div className="row">
          <Cell text={FIO} label={"ФИО"} className="fio"></Cell>
          <Cell
            text={statuses[status - 1].label}
            label={"Статус"}
            className="status"
          ></Cell>
          <Cell text={email} label={"Логин"} className={"login"}></Cell>
          <Cell
            text={"Удалить пользователя"}
            className="delete"
            onClick={deleteUser}
          ></Cell>
        </div>
        <div className="row">
          {/* <Cell
            label={"Пароль"}
            text={showPassword === false ? passwordInDot : password}
            className={"password"}
          ></Cell> */}
          {/* <Cell
            text={"Показать пароль"}
            onClick={() => changeShow(!showPassword)}
            className={"showPassword"}
          ></Cell> */}
        </div>
      </div>

      {status != "1" ? (
        <>
          <Select
            onClick={(cName) => onClickAddCategory(cName)}
            options={categories.filter(
              (c) => !userCategories.includes(c.Title)
            )}
          ></Select>
          <div className="tabs">
            {userCategories.map((categoryName, index) => {
              if (isValid(categoryName))
                return (
                  <CategoryTab
                    key={index}
                    label={categoryName}
                    onClick={() => onClickDeleteCategory(categoryName)}
                  ></CategoryTab>
                );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UserRow;
