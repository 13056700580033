export const createUserText = "Создать пользователя";

export const complectsText = "Комплекты";
export const complectNameText = "Название комплекта";
export const addComplectText = "Добавить комплект";

export const createOprosnikText = "Создать опросник";
export const editOprosnikText = "Редактирование";
export const fillOpronsikText = "Пройти опросник";

export const documentText = "Документ";
export const addText = "Добавить";
export const deleteText = "Удалить";

export const documentUploadText = "Выгрузить";
export const documentDownloadText = "Скачать";
export const documentDisappearText = "Документ отсутствует";

export const T = {
  newComplectNamePlaceholder: "Название нового комплекта",
  templateNamePlaceholder: "Название шаблона",
  getDocuments: "Опросный лист",
  deleteOprosnik: "Удалить",
  Down: "Вниз",
  Up: "Вверх",
  deleteComplect: "Удалить комплект",
};
