import React from "react";
import axios, { post } from "axios";
import { apiUrl } from "../Constants";
import {
  documentDownloadText,
  documentUploadText,
  documentDisappearText,
} from "../language/ru";

class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      message: "",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file);
  }
  onChange(e) {
    let { files, value } = e.target,
      message;
    if (files && files.length > 1) message = `${files.length} files selected`;
    else message = value.split("\\").pop();

    console.log(message);

    // if (message) this.setState({ ...this.state, message })
    // this.setState({ file: e.target.files[0] })
    const url =
      apiUrl +
      "?class=Template&action=changeTemplate&templateId=" +
      this.props.Id;
    const formData = new FormData();

    const { success } = this.props;
    const { Refresh_token, Access_token } =
      this.props.token || JSON.parse(localStorage.getItem("token"));

    let file = e.target.files[0];
    formData.append("file", file);
    e.target.value = null;
    axios({
      url,
      method: "post",
      data: formData,
      headers: {
        Authorization: Access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        success();
        alert("Шаблон успешно загружен");

        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  downloadDoc = () => {
    const url =
      apiUrl +
      "?class=Template&action=getTemplateFile&templateId=" +
      this.props.Id;
    const formData = new FormData();

    const { Refresh_token, Access_token } =
      this.props.token || JSON.parse(localStorage.getItem("token"));
    axios({
      url,
      method: "get",
      responseType: "blob", // important
      headers: {
        Authorization: Access_token,
      },
    })
      .then((result) => {
        // success()
        console.log(result);
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.Title);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  fileUpload(file) {
    const url =
      apiUrl +
      "?class=Template&action=changeTemplate&templateId=" +
      this.props.Id;
    const formData = new FormData();
    const { success } = this.props;

    const { Refresh_token, Access_token } =
      this.props.token || JSON.parse(localStorage.getItem("token"));
    formData.append("file", file);
    axios({
      url,
      method: "post",
      data: formData,
      headers: {
        Authorization: Access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        success();
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const { Id, Title } = this.props;
    return (
      <div>
        <input
          onChange={this.onChange}
          type={"file"}
          name={"file" + Id}
          id={"file" + Id}
          className="inputfile"
        />
        <div className="d-flex">
          <label htmlFor={"file" + Id}>
            <div className="accent-hvr">{documentUploadText}</div>
          </label>
          <div
            onClick={() => this.downloadDoc()}
            className=" accent-hvr"
          >
            {documentDownloadText}
          </div>
        </div>

      </div>
    );
  }
}

export default SimpleReactFileUpload;
